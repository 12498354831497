import React from 'react';
import CopyLink from '../../components/CopyLink';
import GenericPopover from '../../components/GenericPopover';
import {
  formatTimezone,
  formatTimezone2Lines,
  getTransactionTypeString,
} from '../utils';
import { languageMap } from './tableKeymaps';
import certTechLogo from '../../assets/images/certifiedTech.png';


export const shopToolColumns = [
  {
    Header: 'Manufacturer',
    accessor: 'toolManufacturer',
  },
  {
    Header: 'Model',
    accessor: 'toolModel',
  },
  {
    Header: 'Serial',
    accessor: 'toolSerial',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolSerial}
          className='u-font-mono'
          type={'TOOL SERIAL'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolSerial}&queryType=TOOL+SERIAL`}
          showCopy={false}
          divClass='c-btn-link-thin-chunk'
        />
      );
    },
  },
  {
    Header: 'Added On',
    accessor: 'addedOn',
    Cell: (row: any) => {
      return formatTimezone(row.original.addedOn);
    },
  },
];

export const otherUsersColumns = (userTimezone: any) => {
  return [
    {
      Header: 'Username',
      accessor: 'userName',
      Cell: (row: any) => {
        return (
          <CopyLink
            content={row.original.userName}
            type={'USERNAME'}
            redirect={'/userFunctions/getUserSummary'}
            urlParams={`?query=${row.original.userName}&queryType=USERNAME`}
          />
        );
      },
    },
    {
      Header: 'User ID',
      accessor: 'userID',
      Cell: (row: any) => {
        return (
          <CopyLink
            content={row.original.userID}
            type={'USER ID'}
            redirect={'/userFunctions/getUserSummary'}
            urlParams={`?query=${row.original.userID}&queryType=USER+ID`}
          />
        );
      },
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      minWidth: 80,
      Cell: (row: any) => {
        return <span className="u-font-mono">{row.original.firstName}</span>;
      },
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      minWidth: 110,
      Cell: (row: any) => {
        return <span className="u-font-mono">{row.original.lastName}</span>;
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      minWidth: 120,
      Cell: (row: any) => {
        return (
            <div>
              <CopyLink
                content={row.original.email}
                type={'EMAIL'}
                redirect={'/userFunctions/getUserSummary'}
                urlParams={`?query=${row.original.email}&queryType=EMAIL`}
              />
              <>
                {row.original.emailVerified === false ?
                  <div className="u-text-warning">{'(Unverified)'}</div>
                : !row.original.hasOwnProperty('emailVerified') ?
                  <div className="u-text-warning">{'(Verification check bypassed)'}</div>
                : null}
              </>
            </div>
        );
      },
    },
    {
      Header: 'User State',
      accessor: 'userState',
      minWidth: 80,
    },
    {
      Header: 'Shop User Role',
      accessor: 'shopUserRole',
      minWidth: 80,
    },
    {
      Header: 'Shop User State',
      accessor: 'shopUserState',
      minWidth: 80,
    },
    {
      Header: 'Tech Cert Status',
      accessor: 'certifiedTech',
      minWidth: 80,
      Cell: (row: any) => {;
        const phoneVerified = row.original.phoneVerified;
        const idVerified = row.original.idVerified;
        return row.original.certifiedTech ? (
          <div className="u-cursor-pointer">
            <GenericPopover
              anchorElement={
                  <img src={certTechLogo} className="c-logo__techCert" alt="certifiedTechLogo" />
              }
              body={
                <div className="l-flex-column">
                  <div>
                    <span className="u-font-weight-bold">Issued: </span>
                    {row.original.certTechIssueDate ? (
                      formatTimezone(
                        row.original.certTechIssueDate,
                        userTimezone
                      )
                    ) : '<No Date Found>'}
                  </div>
                  <div>
                    <span className="u-font-weight-bold">Expires: </span>
                    {row.original.certTechExpirationDate ? (
                      formatTimezone(
                        row.original.certTechExpirationDate,
                        userTimezone
                      )
                    ) : '<No Date Found>'}
                  </div>
                </div>
              }
              color="#0088cc"
              position="top"
              align="end"
              arrowSize={5}
              closeOnClick={true}
            />
          </div>
        ) : (
          <div className="l-flex-column">
            <div className={`${phoneVerified ? "u-text-success" : "u-text-error"}`}>
              <i className={`fa-solid ${phoneVerified ? "fa-check" : "fa-xmark"}`}></i> Phone
            </div>
            <div className={`${idVerified ? "u-text-success" : "u-text-error"}`}>
              <i className={`fa-solid ${idVerified ? "fa-check" : "fa-xmark"}`}></i> ID
            </div>
            {(phoneVerified && idVerified) && (
              <div className='u-text-error u-font-size-small'>
                *User has not clicked <span className="u-font-weight-bold">"Submit AutoAuth Certification Request"</span> button.
              </div>
            )}
          </div>
        );
      }
    },
    {
      Header: 'Language',
      accessor: 'language',
      minWidth: 80,
      Cell: (row: any) => {
        return (
          languageMap[row.original.language as keyof typeof languageMap] || ''
        );
      },
    },
  ];
}

export const transactionsColumns = [
  {
    Header: 'Amount',
    accessor: (d: any) => Number(d.amount),
    id: 'amount',
    maxWidth: 120,
    Cell: (row: any) => {
      const amount = row.original.amount[0] === '-'
        ? `-$${row.original.amount.slice(1)}`
        : `$${row.original.amount}`;
      const textColor = (row.original.status === 'voided'
        && row.original.type !== 'paymentRefund')
        ? 'u-text-error' : '';
      return (
        <span className={textColor}>{amount}</span>
      )
    }
  },
  {
    Header: 'Payment Method',
    accessor: 'paymentMethod',
    maxWidth: 150,
    minWidth: 120,
    Cell: (row: any) => {
      if (row.original.cardType && row.original.last4) {
        let cardAbr = row.original.cardType;
        switch(row.original.cardType) {
          case 'American Express':
            cardAbr = 'AmEx';
            break;
          case 'Discover':
            cardAbr = 'DC';
            break;
          case 'Mastercard':
            cardAbr = 'MC';
            break;
        }
        return `${cardAbr} ...${row.original.last4}`;
      } else {
        return '';
      }
    }
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: (row: any) => {
      return getTransactionTypeString(row.original.type);
    },
  },
  {
    Header: 'Shop Type',
    accessor: 'shopType',
    maxWidth: 120,
  },
  {
    Header: 'Status',
    accessor: 'status',
    maxWidth: 250,
    minWidth: 200,
    Cell: (row: any) => {
      const textColor = (row.original.status === 'processor_declined'
        || row.original.status === 'voided' || row.original.status.includes('gateway_rejected'))
        ? 'u-text-error'
        : '';
      return (
        <>
          <span className={textColor}>{row.original.status}</span>
        </>
      );
    },
  },
  {
    Header: 'Time',
    accessor: 'time',
    maxWidth: 130,
    Cell: (row: any) => {
      let dateObj = formatTimezone2Lines(row.original.time);
      return (
        <>
          <div>
            {dateObj.date}
            <br />
            {dateObj.time} {dateObj.abbr}
          </div>
        </>
      );
    },
  },
];

/**** AUDIT LOG TABLE DEFINITIONS ****/

/*** BASE DEFINITIONS (all tables will have these) ***/
export const baseLogColumns = [
  {
    Header: 'User ID',
    accessor: 'userID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.userID}
          type={'USER ID'}
          redirect={'/userFunctions/getUserSummary'}
          urlParams={`?query=${row.original.userID}&queryType=USER+ID`}
        />
      );
    },
  },
  {
    Header: 'Action Date',
    accessor: 'actionDate',
    Cell: (row: any) => {
      return formatTimezone(row.original.actionDate);
    },
  },
  {
    Header: 'Action Code',
    accessor: 'actionCode',
  },
  {
    Header: 'Action Message',
    accessor: 'actionMessage',
  },
  {
    Header: 'Fullstory',
    accessor: 'fullstoryLink',
    minWidth: 80,
    maxWidth: 80,
    Cell: (row: { original: any }) => {
      if (row.original.fullstoryLink) {
        return (
          <button
            className="c-btn-icon"
            onClick={() => window.open(row.original.fullstoryLink, '_blank')}
          >
            <div className="c-btn__inner">
              <span
                className="c-btn__icon fa fa-video-camera"
                title="Fullstory Recording"
              />
            </div>
          </button>
        );
      }
      return null;
    },
  },
];

export const baseShopLogColumns = [
  ...baseLogColumns,
  {
    Header: 'Shop ID',
    accessor: 'shopID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.shopID}
          type={'SHOP ID'}
          redirect={'/userFunctions/getShopSummary'}
          urlParams={`?query=${row.original.shopID}&queryType=SHOP+ID`}
        />
      );
    },
  },
];

export const baseErrorLogColumns = [
  ...baseShopLogColumns,
  {
    Header: 'API Error',
    accessor: 'apiErrorMessage',
  },
  {
    Header: 'OEM',
    accessor: 'oemID',
    Cell: (row: any) => {
      return <span className="u-font-mono">{row.original.oemID}</span>;
    },
  },
  {
    Header: 'Source IP',
    accessor: 'sourceIP',
  },
];

// Internal Logs
export const baseEmailLogColumns = [
  ...baseLogColumns,
  {
    Header: 'Email',
    accessor: 'email',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.email}
          className='u-font-mono'
          type={'EMAIL'}
          redirect={'/userFunctions/getUserSummary'}
          urlParams={`?query=${row.original.email}&queryType=EMAIL`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    expander: true,
    Header: 'Body',
    accessor: 'messageBodyText',
    width: 80,
    Expander: ({ isExpanded, ...row }: any) => {
      if (row.original.messageBodyText === 0) {
        return null;
      } else {
        return (
          <div>
            <i
              className={`fa fa-chevron-right ${
                isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
              }`}
              aria-hidden="true"
            ></i>
          </div>
        );
      }
    },
  },
];
//Logs for Login Activity
export const baseLoginActivityLogColumns = [
  // ...baseLogColumns.slice(0, -1),
  ...baseLogColumns.filter(col => col.accessor !== 'fullstoryLink'),
  {
    Header: 'Valid Username',
    accessor: 'isValidUsername',
    Cell: (row: any) => {
      let displayStr = (typeof row.original.isValidUsername) === 'boolean' ? row.original.isValidUsername.toString() : '';
      return <span className="u-font-mono">{displayStr}</span>;
    },
  }
];
// CREATE_SELF_ACCOUNT
export const createSelfAccountColumns = [
  ...baseLogColumns,
  {
    Header: 'Email',
    accessor: 'email',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.email}
          className='u-font-mono'
          type={'EMAIL'}
          redirect={'/userFunctions/getUserSummary'}
          urlParams={`?query=${row.original.email}&queryType=EMAIL`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Username',
    accessor: 'userName',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.userName}
          className='u-font-mono'
          type={'USERNAME'}
          redirect={'/userFunctions/getUserSummary'}
          urlParams={`?query=${row.original.userName}&queryType=USERNAME`}
          showCopy={false}
        />
      );
    },
  },
];

// CONFIRM_SELF_ACCOUNT
export const confirmSelfAccountColumns = baseLogColumns;

// IMPORT_USERS
export const importUsersColumns = baseLogColumns;

// UPDATE_USER_INFO
export const updateUserInfoColumns = baseLogColumns;

// CREATE_SHOP
export const createShopColumns = baseShopLogColumns;

// UPDATE_SHOP_INFO
export const updateShopInfoColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Status Change',
    accessor: 'statusChange',
    Cell: (row: any) => {
      let statusChange = row.original.statusChange;
      if (statusChange !== undefined) {
        if (typeof statusChange === 'boolean') {
          statusChange = statusChange ? 'TRUE' : 'FALSE';
        }
      }
      return statusChange as string;
    },
  },
];

// UPDATE_SHOP_PAYMENT_INFO
export const updateShopPaymentInfoColumns = baseShopLogColumns;

// REMOVE_SHOP_PAYMENT_INFO
export const removeShopPaymentInfoColumns = baseShopLogColumns;

// UPDATE_SHOP_MAX_USERS
export const updateShopMaxUsersColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Number Added',
    accessor: 'numToAdd',
  },
];

// ADD_USER_TO_SHOP
export const addUserToShopColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Added User ID',
    accessor: 'addUserID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.addUserID}
          className='u-font-mono'
          type={'USER ID'}
          redirect={'/userFunctions/getUserSummary'}
          urlParams={`?query=${row.original.addUserID}&queryType=USER+ID`}
          showCopy={false}
        />
      );
    },
  },
];

// REMOVE_USER_FROM_SHOP
export const removeUserFromShopColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Removed User ID',
    accessor: 'removeUserID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.removeUserID}
          className='u-font-mono'
          type={'USER ID'}
          redirect={'/userFunctions/getUserSummary'}
          urlParams={`?query=${row.original.removeUserID}&queryType=USER+ID`}
          showCopy={false}
        />
      );
    },
  },
];

// RESPOND_TO_SHOP_INVITE
export const respondToShopInviteColumns = [
  ...baseLogColumns,
  {
    Header: 'Response',
    accessor: 'response',
  },
];

// SET_USER_STATE_AT_SHOP
export const setUserStateAtShopColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Target User ID',
    accessor: 'targetUserID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.targetUserID}
          className='u-font-mono'
          type={'USER ID'}
          redirect={'/userFunctions/getUserSummary'}
          urlParams={`?query=${row.original.targetUserID}&queryType=USER+ID`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Shop User Role',
    accessor: 'shopUserRole',
  },
  {
    Header: 'Shop User State',
    accessor: 'shopUserState',
  },
];

// REGISTER_TOOL_FOR_SHOP
export const registerToolForShopColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Tool Model',
    accessor: 'toolModel',
  },
  {
    Header: 'Tool Manufacturer',
    accessor: 'toolManufacturer',
  },
  {
    Header: 'Tool Serial',
    accessor: 'toolSerial',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolSerial}
          className='u-font-mono'
          type={'TOOL SERIAL'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolSerial}&queryType=TOOL+SERIAL`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Tool ID',
    accessor: 'toolID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolID}
          className='u-font-mono'
          type={'TOOL ID'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolID}&queryType=TOOL+ID`}
          showCopy={false}
        />
      );
    },
  },
];

// REMOVE_TOOL_FROM_SHOP
export const removeToolFromShopColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Tool ID',
    accessor: 'toolID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolID}
          className='u-font-mono'
          type={'TOOL ID'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolID}&queryType=TOOL+ID`}
          showCopy={false}
        />
      );
    },
  },
];

export const editToolForShopColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Tool ID',
    accessor: 'toolID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolID}
          className='u-font-mono'
          type={'TOOL ID'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolID}&queryType=TOOL+ID`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Tool Name',
    accessor: 'toolName',
  },
  {
    Header: 'Tool Notes',
    accessor: 'toolNotes',
    Cell: (row: any) => {
      if (!row.original.toolNotes) return '';
      if (Array.isArray(row.original.toolNotes)) {
        return row.original.toolNotes.join(', ') as string;
      }
      return JSON.stringify(row.original.toolNotes);
    },
  },
];

// INITIATE_TOOL_RECOVERY
export const initiateToolRecoveryColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Tool Model',
    accessor: 'toolModel',
  },
  {
    Header: 'Tool Manufacturer',
    accessor: 'toolManufacturer',
  },
  {
    Header: 'Tool Serial',
    accessor: 'toolSerial',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolSerial}
          className='u-font-mono'
          type={'TOOL SERIAL'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolSerial}&queryType=TOOL+SERIAL`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'recoveryStatus',
  },
  {
    Header: 'Zendesk',
    accessor: 'requesterTicketID',
    minWidth: 50,
    maxWidth: 50,
    Cell: (row: any) => {
      return row.original.ownerTicketID ? (
        <GenericPopover
          anchorElement={
            <button className="c-btn-icon">
              <div className="c-btn__inner">
                <span
                  className="c-btn__icon fa fa-ticket"
                  title="Zendesk Ticket"
                />
              </div>
            </button>
          }
          body={<span></span>}
          primaryBtnElement={
            <button className="c-btn-medium">
              <div className="c-btn__inner">
                <i className="c-btn__icon fa fa-ticket" />
                Requester
              </div>
            </button>
          }
          secondaryBtnElement={
            <button
              className="c-btn-medium"
              onClick={() =>
                window.open(
                  `https://autoauth.zendesk.com/agent/tickets/${row.original.ownerTicketID}`,
                  '_blank'
                )
              }
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fa fa-ticket" />
                Owner
              </div>
            </button>
          }
          color="#fff"
          position="top"
          align="end"
          arrowSize={5}
          closeOnClick={true}
          primaryAction={ async () => {
            return window.open(
              `https://autoauth.zendesk.com/agent/tickets/${row.original.requesterTicketID}`,
              '_blank'
            );
          }}
          secondaryAction={ async () => {
            return window.open(
              `https://autoauth.zendesk.com/agent/tickets/${row.original.ownerTicketID}`,
              '_blank'
            );
          }}
          timeout={1}
        />
      ) : (
        <button
          className="c-btn-icon"
          onClick={() => {
            window.open(
              `https://autoauth.zendesk.com/agent/tickets/${row.original.requesterTicketID}`,
              '_blank'
            );
          }}
        >
          <div className="c-btn__inner">
            <span className="c-btn__icon fa fa-ticket" title="Zendesk Ticket" />
          </div>
        </button>
      );
    },
  },
  {
    Header: 'Image',
    accessor: 'imageUrl',
    minWidth: 50,
    maxWidth: 50,
    Cell: (row: { original: any }) => {
      if (row.original.imageUrl) {
        return (
          <button
            className="c-btn-icon"
            onClick={() => window.open(row.original.imageUrl, '_blank')}
          >
            <div className="c-btn__inner">
              <span className="c-btn__icon fa-image" title="Image URL" />
            </div>
          </button>
        );
      }
      return null;
    },
  },
];

export const toolRecoveryRequestsColumns = [
  {
    Header: 'Date',
    accessor: 'actionDate',
    minWidth: 120,
    maxWidth: 120,
    Cell: (row: any) => {
      let dateObj = formatTimezone2Lines(row.original.actionDate);
      return (
        <>
          <div>
            {dateObj.date}
            <br />
            {dateObj.time} {dateObj.abbr}
          </div>
        </>
      );
    },
  },
  {
    Header: 'Deadline',
    accessor: 'deadline',
    minWidth: 120,
    maxWidth: 120,
    Cell: (row: any) => {
      if (!row.original.deadline) return null;
      let dateObj = formatTimezone2Lines(row.original.deadline);
      return (
        <>
          <div>
            {dateObj.date}
            <br />
            {dateObj.time} {dateObj.abbr}
          </div>
        </>
      );
    },
  },
  {
    Header: 'Tool Model',
    accessor: 'toolModel',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    Header: 'Tool Manufacturer',
    accessor: 'toolManufacturer',
    minWidth: 150,
    maxWidth: 150,
  },
  {
    Header: 'Tool Serial',
    accessor: 'toolSerial',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolSerial}
          className='u-font-mono'
          type={'TOOL SERIAL'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolSerial}&queryType=TOOL+SERIAL`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Zendesk',
    accessor: 'requesterTicketID',
    minWidth: 65,
    maxWidth: 65,
    Cell: (row: any) => {
      return row.original.ownerTicketID ? (
        <GenericPopover
          anchorElement={
            <button className="c-btn-icon">
              <div className="c-btn__inner">
                <span
                  className="c-btn__icon fa fa-ticket"
                  title="Zendesk Ticket"
                />
              </div>
            </button>
          }
          body={<span></span>}
          primaryBtnElement={
            <button className="c-btn-medium">
              <div className="c-btn__inner">
                <i className="c-btn__icon fa fa-ticket" />
                Requester
              </div>
            </button>
          }
          secondaryBtnElement={
            <button
              className="c-btn-medium"
              onClick={() =>
                window.open(
                  `https://autoauth.zendesk.com/agent/tickets/${row.original.ownerTicketID}`,
                  '_blank'
                )
              }
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fa fa-ticket" />
                Owner
              </div>
            </button>
          }
          color="#fff"
          position="top"
          align="end"
          arrowSize={5}
          closeOnClick={false}
          primaryAction={() =>
            window.open(
              `https://autoauth.zendesk.com/agent/tickets/${row.original.requesterTicketID}`,
              '_blank'
            )
          }
          secondaryAction={() =>
            window.open(
              `https://autoauth.zendesk.com/agent/tickets/${row.original.ownerTicketID}`,
              '_blank'
            )
          }
          timeout={1}
        />
      ) : (
        <button
          className="c-btn-icon"
          onClick={() => {
            window.open(
              `https://autoauth.zendesk.com/agent/tickets/${row.original.requesterTicketID}`,
              '_blank'
            );
          }}
        >
          <div className="c-btn__inner">
            <span className="c-btn__icon fa fa-ticket" title="Zendesk Ticket" />
          </div>
        </button>
      );
    },
  },
  {
    Header: 'Image',
    accessor: 'docKey',
    minWidth: 50,
    maxWidth: 50,
    Cell: (row: { original: any }) => {
      if (row.original.docKey && (row.original.imageRemovalNeeded || !row.original.status.includes('CLOSED'))) {
        return (
          <button
            className="c-btn-icon"
            onClick={() => window.open(`/toolRecoveryImage?docKey=${row.original.docKey}`, '_blank')}
          >
            <div className="c-btn__inner">
              <span className="c-btn__icon fa fa-image" title="Image URL" />
            </div>
          </button>
        );
      } else {
        return null;
      }
    },
  },
  {
    Header: 'FS',
    accessor: 'fullstoryLink',
    minWidth: 50,
    maxWidth: 50,
    Cell: (row: { original: any }) => {
      if (row.original.fullstoryLink) {
        return (
          <button
            className="c-btn-icon"
            onClick={() => window.open(row.original.fullstoryLink, '_blank')}
          >
            <div className="c-btn__inner">
              <span
                className="c-btn__icon fa fa-video-camera"
                title="Fullstory Recording"
              />
            </div>
          </button>
        );
      }
      return null;
    },
  },
];

// IMPORT_SHOP_TOOLS
export const importShopToolsColumns = baseShopLogColumns;

// USER_TOOL_LOGIN
export const userToolLoginColumns = baseLogColumns;

// USER_TOOL_ADINITIATE
export const userToolADInitiateColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Tool Model',
    accessor: 'toolModel',
  },
  {
    Header: 'Tool Manufacturer',
    accessor: 'toolManufacturer',
  },
  {
    Header: 'Tool Serial',
    accessor: 'toolSerial',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolSerial}
          className='u-font-mono'
          type={'TOOL SERIAL'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolSerial}&queryType=TOOL+SERIAL`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Tool ID',
    accessor: 'toolID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolID}
          className='u-font-mono'
          type={'TOOL ID'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolID}&queryType=TOOL+ID`}
          showCopy={false}
        />
      );
    },
  },
];

// USER_TOOL_ADREQUEST
export const userToolADRequestColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Tool Model',
    accessor: 'toolModel',
  },
  {
    Header: 'Tool Manufacturer',
    accessor: 'toolManufacturer',
  },
  {
    Header: 'Tool Serial',
    accessor: 'toolSerial',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolSerial}
          className='u-font-mono'
          type={'TOOL SERIAL'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolSerial}&queryType=TOOL+SERIAL`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Tool ID',
    accessor: 'toolID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolID}
          className='u-font-mono'
          type={'TOOL ID'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolID}&queryType=TOOL+ID`}
          showCopy={false}
        />
      );
    },
  },
];

// USER_TOOL_ADEXECUTE
export const userToolADExecuteColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Tool Model',
    accessor: 'toolModel',
  },
  {
    Header: 'Tool Manufacturer',
    accessor: 'toolManufacturer',
  },
  {
    Header: 'Tool Serial',
    accessor: 'toolSerial',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolSerial}
          className='u-font-mono'
          type={'TOOL SERIAL'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolSerial}&queryType=TOOL+SERIAL`}
          showCopy={false}
        />
      );
    },
  },
  {
    Header: 'Tool ID',
    accessor: 'toolID',
    Cell: (row: any) => {
      return (
        <CopyLink
          content={row.original.toolID}
          className='u-font-mono'
          type={'TOOL ID'}
          redirect={'/shopFunctions/toolLookup'}
          urlParams={`?query=${row.original.toolID}&queryType=TOOL+ID`}
          showCopy={false}
        />
      );
    },
  },
];

// INVITE_USERS
export const inviteUsersColumns = baseShopLogColumns;

// REVOKE_INVITE
export const revokeInviteColumns = [
  ...baseShopLogColumns,
  {
    Header: 'User Email',
    accessor: 'userEmail',
  },
];

// CREATE_SIGNUP_LINK
export const createSignupLinkColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Link ID',
    accessor: 'linkID',
    Cell: (row: any) => {
      return <span className="u-font-mono">{row.original.linkID}</span>;
    },
  },
];

// UPDATE_SIGNUP_LINK
export const updateSignupLinkColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Link ID',
    accessor: 'linkID',
    Cell: (row: any) => {
      return <span className="u-font-mono">{row.original.linkID}</span>;
    },
  },
  {
    Header: 'Link State',
    accessor: 'linkState',
  },
];

// USER_TOOL_ADINITIATE_ERROR
export const userToolADInitiateErrorColumns = [
  ...baseErrorLogColumns,
  {
    Header: 'Error Detail',
    accessor: 'errorDetail',
  },
];

// Sub Columns for USER_TOOL_ADINITIATE_ERROR:
// toolID, toolManufacturer, toolMfgUUID, toolModel, toolModelUUID, toolSerial, vin

// USER_TOOL_ADREQUEST_ERROR
export const userToolADRequestErrorColumns = baseErrorLogColumns;

// Sub columns for USER_TOOL_AD_REQUEST_ERROR:
// toolID, toolMfgUUID, toolModelUUID, toolSerial

// USER_TOOL_ADEXECUTE_ERROR
export const userToolADExecuteErrorColumns = baseErrorLogColumns;

// AC_AUTO_SHOP_SUB_STATE_CHANGE:
export const acAutoShopSubStateChangeColumns = [
  ...baseShopLogColumns,
  {
    Header: 'Subscription Notification',
    accessor: 'shopSubscriptionNotificationState',
  },
  {
    Header: 'Shop Subscription',
    accessor: 'shopSubscriptionState',
  },
];

export const fullLogColumns = baseLogColumns;
