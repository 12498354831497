import React, { FC, useState } from 'react';
import { TextOnly } from '../Text';
import GenericPopover from '../GenericPopover';
import LoaderButton from '../LoaderButton';

interface RefundTransactionProps {
  shopID: string;
  transaction: any;
  handleRefundTransaction: Function;
}

const RefundTransaction: FC<RefundTransactionProps> = ({ shopID, transaction, handleRefundTransaction }) => {

  const [refundReason, setRefundReason] = useState<string>('Terminate shop');
  const [refundAmount, setRefundAmount] = useState<number>(Number(transaction.amount));

  return (
    <GenericPopover
      anchorElement={
        <button className="c-btn-icon">
          <div className="c-btn__inner">
            <span
              className="c-btn__icon fa fa-money-bill-1"
              title="Refund Transaction"
            />
          </div>
        </button>
      }
      body={
        <>
          <strong>
            Are you sure you want to refund this transaction?
          </strong><br />
          <div className="u-flex-row l-flex-between">
            <div className="c-field u-width-40-percent u-margin-bottom-small">
              <label htmlFor="refundAmount" className="c-field__label">
                Amount:
              </label>
              <input
                type={'number'}
                id={'refundAmount'}
                placeholder={`Amount: ${transaction.amount}`}
                value={refundAmount}
                onChange={ (e) => {
                  let newAmount = Number(parseFloat(e.target.value).toFixed(2));
                  newAmount = Math.max(0, newAmount); // Ensure amount is > 0
                  newAmount = Math.min(transaction.amount, newAmount); // Ensure amount is <= transaction amount
                  setRefundAmount(newAmount);
                }}
                className={"u-padding-sides-2rem u-height-5rem"}
              />
            </div>
            <div className="c-field u-width-60-percent u-margin-bottom-small">
              <label htmlFor="refundReason" className="c-field__label">
                <span className="u-text-error">*</span>{' '}
                Refund Reason:
              </label>
              <input
                type={'text'}
                id={'refundReason'}
                placeholder={'e.g. Terminate shop...'}
                value={refundReason}
                onChange={e=>setRefundReason(e.target.value)}
              />
            </div>
          </div>

        </>
      }
      primaryBtnElement={
        <LoaderButton
          className="c-btn-medium c-btn-success"
          disabled={!refundReason || !refundAmount}
          text="Yes, refund it"
          loadingText="Refunding..."
          icon={'fa fa-money-bill-1'}
        />
      }
      secondaryBtnElement={
        <button className="c-btn-medium c-btn-outline">
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
            No, not yet
          </div>
        </button>
      }
      primaryAction={ async () => {
        return await handleRefundTransaction(
          transaction.id,
          shopID,
          refundAmount,
          { reason: refundReason }
        );
      }}
      color="#0088cc"
      position="top"
      align="end"
      arrowSize={5}
      closeOnClick={false}
    />
  );
};

export default RefundTransaction;
