import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import FadeIn from 'react-fade-in';
import Cards from 'react-credit-cards';
import _ from 'underscore';

import { Led } from '../../components/Led';
import { Header } from '../../components/Header';
import Accordion from '../../components/Accordion';
import FieldWithHistory from '../../components/FieldWithHistory';
import FloatingButton from '../../components/FloatingButton';
import FullNameForm from '../../components/FullNameForm';
import LoaderButton from '../../components/LoaderButton';
import DisplayDialog from '../../components/DisplayDialog';
import { Dialog } from '@reach/dialog';
import GenericPopover from '../../components/GenericPopover';
import GenericTable from '../../components/GenericTable';
import Switch from '../../components/Switch';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomReactSelect from '../../components/CustomReactSelect';
import { OptionType } from '../../components/ReactSelect/Option';
import { TextOnly } from '../../components/Text';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import {
  usernameValidate,
  isValidEmail,
  uuidValidate,
  generateFilterRegex,
  buildReceiptPdf,
  scalePdf,
  updateLogData
} from '../../libs/utils';
import {
  getSystemConfig,
  getUserSummary,
  getUserLog,
  getOEMRegionList,
  refundTransaction,
  getUserSummaryByProperty,
} from '../../libs/db-lib';
import {
  shopToolColumns,
  otherUsersColumns,
  transactionsColumns,
  baseLogColumns,
} from '../../libs/tables/columnDefinitions';

import {
  createUserInfoCard,
  createShopStateInfoCard,
  createAddressInfoCard,
  createPaymentInfoCard,
} from '../../libs/ui-constructors';

import {
  getCardIssuer,
  getCreditCardString,
  getRecentSearches,
  setRecentSearches,
} from '../../libs/utils-ts';

import {
  ChildProps,
  QueriedUser,
  RuntimeConfig,
  Shop,
  Transaction,
  Log,
  FullNameQuery,
  RefundObj,
} from '../../types';
import { LogSubcomponent, TransactionSubComponent } from '../../libs/tables/subComponents';
import CopyData from '../../components/CopyData';
import CopyLink from '../../components/CopyLink';
import RefundTransaction from '../../components/RefundTransaction';

interface GetUserSummaryState {
  oemInfo: {};
  oemRegionList: any[];
  queryType: string;
  query: string;
  queryError: string;
  isLoading: boolean;
  fullNameQuery: FullNameQuery;
  queriedUser: QueriedUser;
  queriedUsers: QueriedUser[];
  queriedUsersFiltered: QueriedUser[];
  filter: string;
  currentShop: Shop;
  showSelectUser: boolean;
  includeLog: boolean;
  startDate: string;
  endDate: string;
  config: RuntimeConfig;
  windowWidth: number;
  baseURL: string;
  loadingAddedBy?: string;
  log: Log[];
  groupedLog: Log;
  showLog: boolean;
  selectedActionCodes: OptionType[];
  showUserIDCol: boolean;
  showActionCodeCol: boolean;
  showUserInfo: boolean;
  showToolTableModal: boolean;
  showOtherUsersTableModal: boolean;
  showTransactionsTableModal: boolean;
  showReceiptModal: boolean;
  currentReceipt: any;
  pdfScale: any;
  fetchDataTriggered?: boolean;
  userTableColumns: any[];
}

let state: GetUserSummaryState = {
  oemInfo: {},
  oemRegionList: [] as any[],
  queryType: 'USERNAME',
  query: '',
  queryError: '',
  isLoading: false,
  fullNameQuery: {
    firstName: '',
    lastName: '',
  } as FullNameQuery,
  queriedUser: {
    userID: '',
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    userState: '',
    createdOn: '',
    language: '',
    certifiedTech: false,
    idVerified: false,
    phoneVerified: false,
    userShops: [] as Shop[],
  },
  queriedUsers: [] as QueriedUser[],
  queriedUsersFiltered: [] as QueriedUser[],
  filter: '',
  currentShop: {} as Shop,
  config: {
    baseUrl: '',
    braintreeMode: '',
    merchantId: '',
  },
  windowWidth: 0,
  baseURL: '',

  showSelectUser: false,

  includeLog: false,
  startDate: moment.utc().subtract(30, 'days').format('YYYY-MM-DD'),
  endDate: moment.utc().format('YYYY-MM-DD'),
  log: [] as Log[],
  groupedLog: {} as Log,
  selectedActionCodes: [] as OptionType[],
  showLog: false,
  showUserIDCol: true,
  showActionCodeCol: true,

  showUserInfo: false,
  showToolTableModal: false,
  showOtherUsersTableModal: false,
  showTransactionsTableModal: false,

  showReceiptModal: false,
  currentReceipt: null,
  pdfScale: scalePdf(window.screen.width),

  fetchDataTriggered: false,

  userTableColumns: [] as any[],
};

const topRef = React.createRef<HTMLDivElement>();
let refundAmount = '-0.01';

export default class GetUserSummary extends Component<
  ChildProps,
  GetUserSummaryState
> {
  constructor(props: ChildProps) {
    super(props);
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams.get('query');
    const queryType = searchParams.get('queryType');
    if(query && queryType) {
      state.query = query;
      state.queryType = queryType;
    }

    // This will initial state on first time, but stores previous state
    // when navigating back to the page
    this.state = state;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.location !== prevProps.location) {
      // URL has changed
      this.checkURLChange();
    }
  }

  checkURLChange() {
    const searchParams = new URLSearchParams(window.location.search);
    const query = (searchParams.get('query'))?.replace(/ /g, '+');
    const queryType = searchParams.get('queryType');
    const userID = localStorage.getItem('userID');
    if(query && queryType) {
      this.setState({
        query,
        queryType,
      });
      this.handleSubmit(undefined, query, queryType);
    } else if (userID) {
      this.setState({
        query: userID,
        queryType: 'USER ID'
      });
      localStorage.removeItem('userID');
      this.handleSubmit(undefined, userID, "USER ID");
    }
    this.handleHideTableModal();
  }

  async componentDidMount() {
    this.checkURLChange();
    let config = await getSystemConfig();
    const oemRegionList = await getOEMRegionList();
    const oemInfoAux = {};
    oemRegionList.forEach((element: any) => {
      oemInfoAux[element.oemID] = element.oemName;
      oemInfoAux[element.regionCode] = element.regionName;
    });
    this.setState({
      config: config,
      baseURL: config.baseUrl,
      oemRegionList: oemRegionList,
      oemInfo: oemInfoAux,
    });
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth);
    window.localStorage.setItem(
      'pageData',
      JSON.stringify(
        {
          userInfo: this.state.queriedUser,
          userLog: this.state.log,
        },
        null,
        2
      )
    );

    this.setState({
      userTableColumns: otherUsersColumns(this.props.user.userTimezone),
    });
  }

  componentWillUnmount() {
    // Setting state (global file variable) to this.state allowed persistance
    // of state when navigating back to the component later
    state = {
      ...this.state,
      isLoading: false,
      showToolTableModal: false,
      showOtherUsersTableModal: false,
      showTransactionsTableModal: false,
      fetchDataTriggered: false,
    };
  }

  setWindowWidth = () => {
    setTimeout(this.setStateWidth.bind(this), 20);
  };

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
    if (this.state.windowWidth <= 500 && document.querySelectorAll('.rccs')) {
      (
        document.querySelectorAll('.rccs') as NodeListOf<HTMLDivElement>
      ).forEach((element) => {
        element.style.transform = `scale(${this.state.windowWidth / 500})`;
      });
    }
  }

  handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | string,
    fieldId?: string
  ) => {
    if (typeof event === 'string') {
      let error = '';
      switch (this.state.queryType) {
        case 'USERNAME':
          error = !usernameValidate(event.trim())
            ? 'Username must be at least 8 alphanumeric lowercase characters starting with a letter'
            : '';
          event = event.toLowerCase();
          break;
        case 'EMAIL':
          error = !isValidEmail(event.trim())
            ? 'Email format is not valid!'
            : '';
          event = event.toLowerCase();
          break;
        case 'USER ID':
          error = !uuidValidate(event.trim())
            ? 'User ID must be in this format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
            : '';
          event = event.toLowerCase();
          break;
      }
      this.setState({
        [fieldId as any]: event,
        queryError: error,
      } as any);
    } else if (event) {
      let eventValue = event.target.value;
      this.setState({
        [event.target.id]: eventValue,
        queryError: '',
      } as any);
    }
  };

  handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let eventValue = e.target.value;
    if (eventValue === 'EMAIL') {
      this.setState((prevState) => ({
        query: prevState.query.toLowerCase(),
      }));
    }

    this.setState({
      queryType: eventValue,
      queryError: '',
      query:''
    });
  };

  handleFullNameChange = (fullNameObj: FullNameQuery) => {
    this.setState({
      fullNameQuery: fullNameObj,
    });
  };

  handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter: e.target.value,
    });

    const updateFilter = _.debounce((f: string) => {
      this.updateFilteredList(f);
    }, 300);

    updateFilter(e.target.value);
  };

  updateFilteredList = (filter: string) => {
    let userList = this.state.queriedUsers;

    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);
      userList = this.state.queriedUsers.filter((u) => {
        return Object.values(u).some((value) => regexStr.test(value));
      });
    }
    this.setState({
      queriedUsersFiltered: userList || [],
    });
  };

  handleRangeChange = (value: any) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    });
  };

  validateForm() {
    const { query, queryType, fullNameQuery } = this.state;
    switch (queryType) {
      case 'USERNAME':
        // 07/24/2024 JBump commented this out so that Enterprise
        //            Directory Services user can be searched
        // return usernameValidate(query);
        return true;
      case 'EMAIL':
        return isValidEmail(query);
      case 'USER ID':
        // 07/24/2024 JBump commented this out so that Enterprise
        //            Directory Services user can be searched
        // return uuidValidate(query);
        return true;
      case 'FIRST/LAST NAME':
        return fullNameQuery.firstName || fullNameQuery.lastName;
      default:
        return query.trim().length > 0;
    }
  }

  handleSubmit = async (
    e?: React.SyntheticEvent,
    query?: string,
    queryType?: string,
    queryBySelectUser?: string
  ) => {
    e?.preventDefault();

    this.setState({ isLoading: true, showLog: false, showSelectUser: false, fetchDataTriggered: true });
    if(!queryType){
      queryType = this.state.queryType;
    }
    if(!query){
      query = this.state.query;
    }
    let { fullNameQuery } = this.state;
    let result:any = null;
    if (queryBySelectUser) {
      queryType = 'USER ID';
      query = queryBySelectUser;
    }

    if (queryType === 'USER ID') {
      queryType = 'USERID';
    }

    if (queryType === 'USERNAME') {
      query = query!.toLowerCase();
    }

    if (queryType === 'FIRST/LAST NAME') {
      const usersByFullName = await getUserSummaryByProperty(
        'FIRST/LAST NAME',
        fullNameQuery
      );
      this.setState({
        queriedUsers: usersByFullName,
        queriedUsersFiltered: usersByFullName,
        showUserInfo: false,
        showLog: false,
        showSelectUser: true,
        isLoading: false,
        query: ''
      });

      let firstNameRecentSearches = getRecentSearches('firstName');
      let lastNameRecentSearches = getRecentSearches('lastName');

      if (firstNameRecentSearches) {
        if (firstNameRecentSearches.length > 4) firstNameRecentSearches.pop();

        if (fullNameQuery.firstName) {
          if (
            firstNameRecentSearches.indexOf(
              fullNameQuery.firstName as string
            ) === -1
          ) {
            firstNameRecentSearches.unshift(
              fullNameQuery.firstName as string
            );
          }
        }
      } else {
        if (fullNameQuery.firstName) {
          firstNameRecentSearches = [fullNameQuery.firstName];
        }
      }

      if (lastNameRecentSearches) {
        if (lastNameRecentSearches.length > 4) lastNameRecentSearches.pop();

        if (fullNameQuery.lastName) {
          if (
            lastNameRecentSearches.indexOf(
              fullNameQuery.lastName as string
            ) === -1
          ) {
            lastNameRecentSearches.unshift(fullNameQuery.lastName as string);
          }
        }
      } else {
        if (fullNameQuery.lastName) {
          lastNameRecentSearches = [fullNameQuery.lastName];
        }
      }
      setRecentSearches('firstName', firstNameRecentSearches);
      setRecentSearches('lastName', lastNameRecentSearches);
      return;
    }

    result = await getUserSummary(queryType, query);

    if (!result || Object.hasOwn(result, 'error')) {
      if (result && [401, 403].indexOf(result.error?.status) === -1) {
        this.props.handleShowAlert('Error', result.error);
      }
      this.setState({
        showUserInfo: false,
        isLoading: false,
      });
      return;
    } else {
      let pageData: { [k: string]: any } = {};
      pageData['userInfo'] = result;
      if (this.state.includeLog) {
        const startDateStr = moment(this.state.startDate).startOf('day').utc().toISOString();
        // If the end date is the current date, make the end date the current moment for most up-to-date logs
        const endDateStr = moment(this.state.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
          ? moment().utc().toISOString()
          : moment(this.state.endDate).endOf('day').utc().toISOString();
        const logs = await getUserLog(
          result.userID,
          startDateStr,
          endDateStr
        );

        await updateLogData(logs, this.state.oemInfo);

        let groups = logs.reduce((group: any, entry: any) => {
          group[entry.actionCode] = group[entry.actionCode] || [];
          group[entry.actionCode].push(entry);
          return group;
        }, Object.create(null));
        this.setState({
          log: logs,
          groupedLog: groups,
          showLog: true,

        });

        pageData['userLog'] = logs;
      }
      this.setState({
        queriedUser: result,
        showUserInfo: true,
        isLoading: false,
      });
      window.localStorage.setItem(
        'pageData',
        JSON.stringify(pageData, null, 2)
      );
    }
    this.setState({
      isLoading: false,
    });

    this.setStateWidth(); // ensures resizing of Card component to fit screen

    let recentSearches = getRecentSearches(queryType);

    if (recentSearches) {
      if (recentSearches.length > 4) recentSearches.pop();
      if (recentSearches.indexOf(query) === -1) recentSearches.unshift(query);
    } else {
      recentSearches = [query];
    }
    setRecentSearches(queryType, recentSearches);
  };

  handleRefundTransaction = async (transactionID: string, shopID: string, amount: string, refundDetails: RefundObj) => {
    const response = await refundTransaction(transactionID, shopID, amount, refundDetails);

    if (response.success){
      this.props.handleShowAlert('Success', response.success);
      const newTransaction = response.result;
      let updatedShopTransactions = this.state.currentShop.shopPaymentInfo.transactions;
      updatedShopTransactions = updatedShopTransactions?.map((transaction) => {
        if (transaction.id === transactionID) {
          let updatedTransaction = transaction;
          updatedTransaction.refundedTo = newTransaction.id;
          return updatedTransaction;
        }
        return transaction;
      });

      updatedShopTransactions?.unshift(newTransaction);

      let newShop = this.state.currentShop;
      newShop.shopPaymentInfo.transactions = updatedShopTransactions;
      newShop.transactionsCount = newShop.transactionsCount + 1;

      let newShopsArr = this.state.queriedUser.userShops.map((shop) => {
        if (shop.shopID === shopID) {
          return newShop;
        }
        return shop;
      });
      let newUser = { ...this.state.queriedUser, userShops: newShopsArr };
      this.setState({
        currentShop: newShop,
        queriedUser: newUser,
      });
    } else {
      this.props.handleShowAlert('Error', response.error);
    }
  };

  getPlaceholder = () => {
    switch (this.state.queryType) {
      case 'USERNAME':
        return "ex. 'john.smith'";
      case 'EMAIL':
        return "ex. 'john.smith@email.com'";
      case 'USER ID':
        return "ex. 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'";
    }
  };

  getQueryTypeHelper = () => {
    switch (this.state.queryType) {
      case 'USERNAME':
      case 'EMAIL':
      case 'USER ID':
        return `${this.state.queryType} (lowercase)`;
      case 'FIRST/LAST NAME':
        return `${this.state.queryType} (case-sensitive)`;
    }
  };

  handleShowTableModal = (modal: string, shop: Shop) => {
    let modalToShow = '';
    switch (modal) {
      case 'tools':
        modalToShow = 'showToolTableModal';
        break;
      case 'otherUsers':
        modalToShow = 'showOtherUsersTableModal';
        break;
      case 'transactions':
        modalToShow = 'showTransactionsTableModal';
        break;
      default:
        break;
    }
    this.setState({
      [modalToShow]: true,
      currentShop: shop,
    } as any);
  };

  handleHideTableModal = () => {
    const self = this;
    document.querySelector('.c-modal-slider-75')?.classList.add('closed');
    setTimeout(() => {
      self.setState({
        showToolTableModal: false,
        showOtherUsersTableModal: false,
        showTransactionsTableModal: false,
      });
    }, 350);
  };

  handleViewReceipt = (transactionId, e) => {
    e.preventDefault();
    const transaction = this.state.currentShop.shopPaymentInfo.transactions.find(
      (trans) => {
        return trans.id === transactionId;
      }
    );
    const owner = this.state.currentShop.shopUserRole === 'OWNER'
      ? this.state.queriedUser
      : this.state.currentShop.otherShopUsers.find(
        (user) => {
          return user.shopUserRole === 'OWNER';
        }
      );
    const receiptDoc = buildReceiptPdf(
      transaction,
      this.state.currentShop,
      owner,
      this.state.oemRegionList,
    );
    this.setState({ currentReceipt: receiptDoc });
    this.setState({ showReceiptModal: true });
  };

  handleCancelReceiptModal() {
    const self = this;
    document?.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      self.setState({ showReceiptModal: false });
    }, 350);
  }

  render() {
    let newTransCol = transactionsColumns.map(element=>{
        if(element.Header==='OEM' && this.state.oemRegionList){
          return {
            Header: 'OEM',
            accessor: 'oemIDs',
            Cell: (row: any) => {
              const oemInterfaceFiltered = this.state.oemRegionList.filter(oemInter=>oemInter.regionCode===row.original.regionCode)
              if(row.original.oemIDs){
                  const oemList = row.original.oemIDs.map((oemID: string)=>{
                      let oem = oemInterfaceFiltered.find(oem=>oem.oemID===oemID);
                      return <li key={oemID}>{oem.oemName}</li>
                  })
                  return <ul className='list-style-none u-padding-left-none'>{oemList}</ul>
              } else {
                  return ''
              }
            },
          }
        } else{
          return element
        }
    })
    const { queriedUser, showUserInfo } = this.state;
    const isNotExternal = this.props.user.userType !== 'EXTERNAL';
    const isAdvancedRole = ['MANAGER', 'ADMIN'].includes(
      this.props.user.userType
    );
    const currentShopTransactions = (this.state.currentShop.shopPaymentInfo?.transactions as any[])?.map(tran => {
      tran.oemNames = tran.oemIDs?.map((oemID: string) => this.state.oemInfo[oemID]);
      tran.addedOemNames = !Array.isArray(tran.addedOems) ? [] : tran.addedOems?.map((oemID: string) => this.state.oemInfo[oemID]);
      return tran;
    });

    let userInfo =
      queriedUser && createUserInfoCard(queriedUser, this.state.windowWidth);
    let shopInfoAccordions =
      queriedUser &&
      queriedUser.userShops
        .sort((a, b) => a.shopName.localeCompare(b.shopName))
        .map((shop: Shop) => {
          let shopStateCard = createShopStateInfoCard(
            shop,
            this.state.windowWidth,
            this.props.handleShowAlert
          );

          let crmStateCard = createShopStateInfoCard(
            shop,
            this.state.windowWidth,
            this.props.handleShowAlert,
            true // isCRMCard
          );

          let creditCardNumber =
            getCreditCardString(shop.shopPaymentInfo?.cardType) +
            (shop.shopPaymentInfo?.last4 || '••••');
          let addressInfoCard = createAddressInfoCard(
            shop,
            this.state.windowWidth
          );

          let paymentInfoCard = createPaymentInfoCard(
            shop,
            this.state.windowWidth,
            this.state.config,
          );

          let crmPaymentInfoCard = createPaymentInfoCard(
            shop,
            this.state.windowWidth,
            this.state.config,
            true // isCRMCard
          );

          let transactionsCount =
            shop.transactionsCount || shop.shopPaymentInfo?.transactions?.length || 0;

          let transactionsCard = this.state.windowWidth < 1220 ? (
            <div className="u-margin-bottom-large">
              <label className="mobile-display-label">
                Transactions
              </label>
              {transactionsCount > 0 ? (
                <div className="l-flex-align-stretch">
                  <div className="mobile-display-value-with-button">
                    {transactionsCount}{' '}
                  </div>
                  <div
                    className="mobile-display-value-button"
                    onClick={() => this.handleShowTableModal('transactions', shop)}
                  >
                    <i className="c-btn__icon fa fa-chevron-right u-padding-left" />
                  </div>
                </div>
              ) : (
                transactionsCount
              )}
            </div>
          ) : (
            <tr className="card-tr">
              <th className="card-th">Transactions</th>
              <td className="card-td">
                {transactionsCount > 0 ? (
                  <div className={"l-flex-align-center"}>
                    {transactionsCount}{' '}
                    <button
                      className="c-btn-icon"
                      onClick={() => this.handleShowTableModal('transactions', shop)}
                    >
                      <div className="c-btn__inner">
                        <i className="c-btn__icon fa fa-chevron-right" />
                      </div>
                    </button>
                  </div>
                ) : (
                  transactionsCount
                )}
              </td>
            </tr>
          );

          return (
            <Accordion
              title={shop.shopName ? 'Shop Name: ' + shop.shopName : '<No Shop Name>'}
              key={shop.shopID}
              icon={<span className="material-icons">store</span>}
              subtitle={shop.shopUserRole === 'OWNER' ? 'OWNER' : undefined}
            >
              {/* Basic Info */}
              {/* Desktop */}
              {this.state.windowWidth > 1220 && (
                <>
                  <div className="c-card u-margin-bottom-large">
                    <h2 className="c-card__title">Basic User Info</h2>
                    <div className="c-card__description">
                      <table className="card-table">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr className="card-tr" key="1">
                            <th className="card-th">Shop ID: </th>
                            <td className="card-td u-font-mono">
                              {
                                <CopyLink
                                  content={shop.shopID}
                                  redirect={'/shopFunctions/getShopSummary'}
                                  type={'SHOP ID'}
                                  action={'search'}
                                  urlParams={`?query=${shop.shopID}&queryType=SHOP+ID`}
                                />
                              }
                            </td>
                            <th className="card-th">Max Users: </th>
                            <td className="card-td">{shop.shopMaxUsers}</td>
                          </tr>
                          <tr className="card-tr" key="2">
                            <th className="card-th">User's Shop Role: </th>
                            <td className="card-td">{shop.shopUserRole}</td>

                            {isNotExternal && (
                              <>
                                <th className="card-th">Number of Tools: </th>
                                <td className="card-td">
                                  <div className="l-flex-align-center">
                                    {shop.shopToolsCount}{' '}
                                    <button
                                      className="c-btn-icon"
                                      onClick={() =>
                                        this.handleShowTableModal('tools', shop)
                                      }
                                    >
                                      <div className="c-btn__inner">
                                        <i className="c-btn__icon fa fa-chevron-right" />
                                      </div>
                                    </button>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                          <tr className="card-tr" key="3">
                            <th className="card-th">User's Shop State: </th>
                            <td className="card-td">{shop.shopUserState}</td>
                            <th className="card-th">Number of Other Users: </th>
                            <td className="card-td">
                              {shop.otherShopUsersCount > 0 ? (
                                <div className="l-flex-align-center">
                                  {shop.otherShopUsersCount}{' '}
                                  <button
                                    className="c-btn-icon"
                                    onClick={() =>
                                      this.handleShowTableModal(
                                        'otherUsers',
                                        shop
                                      )
                                    }
                                  >
                                    <div className="c-btn__inner">
                                      <i className="c-btn__icon fa fa-chevron-right" />
                                    </div>
                                  </button>
                                </div>
                              ) : (
                                shop.otherShopUsersCount
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th></th>
                            <td></td>
                            <th className="card-th">Max Tech Certs: </th>
                            <td className="card-td">
                              <div className="l-flex-align-center">
                                {shop.shopMaxTechCerts ?? 0}
                              </div>
                            </td>
                          </tr>

                          {shop.shopMaxTechCerts && (
                              <tr>
                                <th></th>
                                <td></td>
                                <th className="card-th">Number of Used Tech Certs: </th>
                                <td className="card-td">
                                  <div className="l-flex-align-center">
                                    {shop.numUsedTechCerts ?? 0}
                                  </div>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Shop State and Address Info */}
                  {/* Desktop */}
                  {isNotExternal && (
                    <div className="l-flex-between">
                      <div>
                        <div className="c-card u-margin-right-xlarge u-margin-bottom">
                          <h2 className="c-card__title">Shop States</h2>
                          <div className="c-card__description">
                            <table className="card-table">
                              <tbody>{shopStateCard}</tbody>
                            </table>
                            {shop.hasOwnProperty('crmState') && (
                              <table className="card-table u-padding-top-xlarge">
                                <tbody>{crmStateCard}</tbody>
                              </table>
                            )}
                          </div>
                        </div>
                        <div className="c-card u-margin-right-xlarge">
                          <h2 className="c-card__title">Address Info</h2>
                          <div className="c-card__description">
                            <table className="card-table">
                              <colgroup>
                                <col />
                                <col />
                              </colgroup>
                              <tbody>{addressInfoCard}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {/* Payment Info */}
                      <div className="c-card u-margin-left-xlarge">
                        <h2 className="c-card__title">Payment Info</h2>
                        <div className="c-card__description">
                          <table className="card-table">
                            <tbody>{paymentInfoCard}</tbody>
                          </table>
                          {shop.hasOwnProperty('crmState') && (
                            <table className="card-table u-padding-top-xlarge">
                              <tbody>{crmPaymentInfoCard}</tbody>
                            </table>
                          )}
                          <table className="card-table u-padding-top-xlarge">
                            <tbody>{transactionsCard}</tbody>
                          </table>
                          <div className="u-margin-top-xlarge u-margin-bottom-large">
                            <Cards
                              cvc=""
                              expiry={
                                shop.shopPaymentInfo?.expirationDate || '00/00'
                              }
                              name=" "
                              number={creditCardNumber}
                              issuer={getCardIssuer(
                                shop.shopPaymentInfo?.cardType || 'None'
                              )}
                              preview={true}
                            />
                          </div>
                          {!getCardIssuer(shop.shopPaymentInfo?.cardType) && (
                            <table className="card-table">
                              <colgroup>
                                <col />
                                <col />
                              </colgroup>
                              <tbody>
                                <tr className="card-tr" key={shop.shopID}>
                                  <th className="card-th">Card Type: </th>
                                  <td className="card-td">
                                    {shop.shopPaymentInfo?.cardType || ''}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* Mobile */}
              {this.state.windowWidth <= 1220 && (
                <>
                  <div className="c-card u-margin-bottom-large">
                    <h2 className="c-card__title">Basic Info</h2>
                    <div className="c-card__description">
                      <div className="u-margin-bottom-large">
                        <label className="mobile-display-label">Shop ID</label>
                        <div className="mobile-display-value u-font-mono">
                          {shop.shopID}
                        </div>
                      </div>

                      <div className="u-margin-bottom-large">
                        <label className="mobile-display-label">
                          Max Users:
                        </label>
                        <div className="mobile-display-value">
                          {shop.shopMaxUsers}
                        </div>
                      </div>

                      <div className="u-margin-bottom-large">
                        <label className="mobile-display-label">
                          User's Shop Role
                        </label>
                        <div className="mobile-display-value">
                          {shop.shopUserRole}
                        </div>
                      </div>

                      <div className="u-margin-bottom-large">
                        <label className="mobile-display-label">
                          User's Shop State
                        </label>
                        <div className="mobile-display-value">
                          {shop.shopUserState}
                        </div>
                      </div>

                      {isNotExternal && (
                        <div className="u-margin-bottom-large">
                          <label className="mobile-display-label">
                            Number of Tools
                          </label>
                          <div className="l-flex-align-stretch">
                            <div className="mobile-display-value-with-button">
                              {shop.shopToolsCount}{' '}
                            </div>
                            <div
                              className="mobile-display-value-button"
                              onClick={() =>
                                this.handleShowTableModal('tools', shop)
                              }
                            >
                              <i className="c-btn__icon fa fa-chevron-right u-padding-left" />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="u-margin-bottom-large">
                        <label className="mobile-display-label">
                          Number of Other Users
                        </label>

                        {shop.otherShopUsersCount > 0 ? (
                          <div className="l-flex-align-stretch">
                            <div className="mobile-display-value-with-button">
                              {shop.otherShopUsersCount}{' '}
                            </div>
                            <div
                              className="mobile-display-value-button"
                              onClick={() =>
                                this.handleShowTableModal('otherUsers', shop)
                              }
                            >
                              <i className="c-btn__icon fa fa-chevron-right u-padding-left" />
                            </div>
                          </div>
                        ) : (
                          <div className="mobile-display-value">
                            {shop.otherShopUsersCount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {isNotExternal && (
                    <>
                      <div className="c-card u-margin-bottom-large">
                        <h2 className="c-card__title">Shop States</h2>
                        <div className="c-card__description">
                          {shopStateCard}
                        </div>
                      </div>

                      <div className="c-card u-margin-bottom-large">
                        <h2 className="c-card__title">Address Info</h2>
                        <div className="c-card__description">
                          {addressInfoCard}
                        </div>
                      </div>

                      <div className="c-card u-margin-bottom-large">
                        <h2 className="c-card__title">Payment Info</h2>
                        <div className="c-card__description">
                          {paymentInfoCard}
                          <div className="l-flex-center u-margin-top-xlarge u-margin-bottom-large">
                            <Cards
                              cvc=""
                              expiry={
                                shop.shopPaymentInfo?.expirationDate || '00/00'
                              }
                              name=" "
                              issuer={getCardIssuer(
                                shop.shopPaymentInfo?.cardType || 'None'
                              )}
                              number={creditCardNumber}
                              preview={true}
                            />
                          </div>
                          <div>
                            {!getCardIssuer(shop.shopPaymentInfo?.cardType) && (
                              <div className="u-margin-bottom-large">
                                <label className="mobile-display-label">
                                  Card Type
                                </label>
                                <div className="mobile-display-value">
                                  {shop.shopPaymentInfo?.cardType || '-'}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </Accordion>
          );
        });

    return (
      <>
        <Header context="User Functions" title="Get User Summary" />
        <FloatingButton
          onClick={() => {
            if (topRef && topRef.current) {
              topRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }
          }}
        />
        <div className="l-container" ref={topRef}>
          <form>
            <div className="c-field">
              <label htmlFor="queryType" className="c-field__label">
                Query Type
              </label>
              <div className="c-select">
                <select
                  onChange={this.handleSelectionChange}
                  id="queryType"
                  value={this.state.queryType}
                >
                  {['USERNAME', 'EMAIL', 'USER ID', 'FIRST/LAST NAME'].map(
                    (type, i) => {
                      return <option key={i}>{type}</option>;
                    }
                  )}
                </select>
              </div>
            </div>
            <div
              className={`c-field ${
                this.state.queryError && 'u-margin-bottom-none'
              }`}
            >
              <label
                htmlFor="query"
                className="c-field__label u-margin-top-large"
              >
                {this.getQueryTypeHelper()}
              </label>

              {this.state.queryType === 'FIRST/LAST NAME' ? (
                <FullNameForm
                  handleSubmit={() => {
                    if (this.validateForm()) {
                      this.handleSubmit();
                    }
                  }}
                  handleChange={this.handleFullNameChange}
                />
              ) : (
                <>
                  <FieldWithHistory
                    fieldId="query"
                    searchKey={this.state.queryType}
                    selected={this.state.query}
                    onInputChange={this.handleChange}
                    onChange={this.handleChange}
                    placeholder={this.getPlaceholder()}
                    onEnter={() => {
                      if (this.validateForm()) this.handleSubmit();
                    }}
                  />
                  {this.state.queryError && (
                    <div className="u-text-error u-margin-left-large">
                      <small>{this.state.queryError}</small>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="c-field">
              <Switch
                isChecked={this.state.includeLog}
                states={{
                  active: 'Include User Log',
                  inactive: 'Include User Log',
                }}
                handleCheck={(e) =>
                  this.setState({
                    includeLog: e,
                  })
                }
              />
            </div>
            {this.state.includeLog && (
              <div className="c-field">
                <label
                  htmlFor="query"
                  className="c-field__label u-margin-top-large"
                >
                  Date Range
                </label>
                <CustomDatePicker
                  lang="en"
                  idPrefix="tool"
                  value={{
                    start: this.state.startDate,
                    end: this.state.endDate,
                    name: 'Last 30 Days',
                  }}
                  drops={'down'}
                  onChange={this.handleRangeChange}
                  user={this.props.user}
                />
              </div>
            )}

            <div className="c-field">
              <LoaderButton
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text="Get Summary"
                loadingText="Searching..."
                onClick={this.handleSubmit}
              />
            </div>
          </form>
        </div>

        {this.state.showSelectUser && (
          <FadeIn visible={!this.state.isLoading && this.state.showSelectUser}>
            <div>
              <h2>{this.state.queriedUsersFiltered.length} result(s) found:</h2>
            </div>
            <div className="c-field u-margin-top-small u-margin-right-small">
              <label htmlFor="filter" className="c-field__label u-is-vishidden">
                Filter
              </label>
              <input
                type="text"
                id="filter"
                maxLength={50}
                className="c-input u-font-mono"
                placeholder="Filter (Username, User ID, Email, First/Last Name)"
                value={this.state.filter}
                onChange={this.handleChangeFilter}
              />
              <i className="c-field__input-icon fal fa-search" />
            </div>
            {this.state.queriedUsersFiltered.map((user: QueriedUser) => {
              return (
                <div className="c-box" key={user.userID}>
                  <div className="l-flex-wrap">
                    <section className="c-section-alt l-flex-wrap">
                      <div className="c-section-alt__content">
                        <label className="c-section-alt__label">Username</label>
                        <span className="c-section-alt__value u-font-mono">
                          {user.userName}
                        </span>
                      </div>
                      <div className="c-section-alt__content">
                        <label className="c-section-alt__label">ID</label>
                        <span className="c-section-alt__value u-font-mono">
                          {user.userID}
                        </span>
                      </div>
                      <div className="c-section-alt__content">
                        <label className="c-section-alt__label">Email</label>
                        <span className="c-section-alt__value">
                          {user.email}
                        </span>
                      </div>
                      <div className="c-section-alt__content">
                        <label className="c-section-alt__label">
                          Full Name
                        </label>
                        <span className="c-section-alt__value u-font-mono">
                          {`${user.firstName} ${user.lastName}`}
                        </span>
                      </div>
                    </section>
                    <button
                      className="c-btn"
                      onClick={() => {
                        this.handleSubmit(undefined, undefined, undefined, user.userID);
                      }}
                    >
                      <div className="c-btn__inner">Get Summary</div>
                    </button>
                  </div>
                </div>
              );
            })}
          </FadeIn>
        )}
        <FadeIn visible={!this.state.isLoading && showUserInfo}>
          <h1>Basic Info</h1>
          <hr />
          <CopyData info={queriedUser}/>
          <div className="u-margin-left-none">
            <div className="c-card">
              <h2 className="c-card__title">User's Name: &nbsp;&nbsp;{queriedUser.firstName} {queriedUser.lastName}</h2>
              <div className="c-card__description">
                {this.state.windowWidth < 1220 ? (
                  userInfo
                ) : (
                  <table className="card-table">
                    <colgroup>
                      <col className="card-table-col-labels-20" />
                      <col />
                    </colgroup>
                    <tbody>{userInfo}</tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className="u-margin-top-xlarge">
            <h1>User Shops</h1>
            <hr />
            {shopInfoAccordions}
          </div>
        </FadeIn>

        <FadeIn visible={!this.state.isLoading && this.state.showLog}>
          <h1>User Log</h1>
          <hr />
          <Accordion
            title={`User Log for ${this.state.queriedUser.userName}`}
            key={`${this.state.query}-log`}
            icon={<span className="material-icons">description</span>}
            maxHeightOverride={'fit-content'}
          >
            <div className="u-text-center">
              {queriedUser && (
                <>
                  <h2>
                    Log Table For User:{' '}
                    <span className="u-font-mono">{`${queriedUser.userName} (${queriedUser.userID})`}</span>
                  </h2>
                </>
              )}
            </div>
            <GenericTable
              fetchDataTriggered={this.state.fetchDataTriggered}
              resetFetchDataTriggered={() => this.setState({fetchDataTriggered: false})}
              data={this.state.log || []}
              filterKeys={['actionMessage', 'actionCode', 'actionDate']}
              columnDefs={baseLogColumns.slice(1)}
              defaultSorted={[
                {
                  id: 'actionDate',
                  desc: true,
                },
              ]}
              savedColumnsId={'fullLogTableColumns'}
              subComponentRenderer={LogSubcomponent}
              getTrProps={(state: any, rowInfo: any, column: any) => {
                if (rowInfo) {
                  if (rowInfo.original.actionCode === 'UPDATE_USER_INFO') {
                    return {
                      className: 'hide-expander',
                    };
                  }
                }
                return {};
              }}
            />
          </Accordion>
        </FadeIn>

        {/* Tool, OtherUser, and Transactions Tables */}
        <DisplayDialog
          title={`${this.state.currentShop.shopName} Tools` || 'Shop Tools'}
          isOpen={this.state.showToolTableModal}
          onDismiss={this.handleHideTableModal}
        >
          <GenericTable
            id="SHOP_TOOLS-table"
            data={this.state.currentShop.allShopTools}
            filterKeys={[
              'toolModel',
              'toolManufacturer',
              'toolSerial',
              'addedBy',
            ]}
            columnDefs={[
              ...shopToolColumns,
              {
                Header: 'Added By',
                accessor: 'addedBy',
                Cell: (row: any) => {
                  return this.state.loadingAddedBy === row.original.toolID ? (
                    <button className="c-btn-icon">
                      <div className="c-btn__inner">
                        <i className="c-btn__icon fal fa-spinner-third spinning" />
                      </div>
                    </button>
                  ) : (
                    <button
                      className="c-btn-link-thin u-font-mono"
                      onClick={async () => {
                        this.setState({
                          loadingAddedBy: row.original.toolID,
                        });
                        let user = await getUserSummary(
                          'USERID',
                          row.original.addedBy
                        );

                        let userInfo = createUserInfoCard(
                          user,
                          this.state.windowWidth
                        );
                        let modalBody =
                          this.state.windowWidth < 1220 ? (
                            <div className="c-card">
                              <h2 className="c-card__title">{`${user.firstName} ${user.lastName}`}</h2>
                              <div className="c-card__description">
                                {userInfo}
                              </div>
                              <Led status={user.userState as any} />
                            </div>
                          ) : (
                            <div className="c-card">
                              <h2 className="c-card__title">{`${user.firstName} ${user.lastName}`}</h2>
                              <div className="c-card__description">
                                <table className="card-table">
                                  <colgroup>
                                    <col className="card-table-col-labels" />
                                    <col />
                                  </colgroup>
                                  <tbody>{userInfo}</tbody>
                                </table>
                              </div>
                              <Led status={user.userState as any} />
                            </div>
                          );
                        this.props.handleShowAlert(
                          `User Summary`,
                          modalBody,
                          false
                        );
                        this.setState({
                          loadingAddedBy: '',
                        });
                      }}
                    >
                      {row.original.addedBy}
                    </button>
                  );
                },
              },
            ]}
            defaultSorted={[
              {
                id: 'toolSerial',
                desc: false,
              },
            ]}
            savedColumnsId="toolsTableColumns"
            subComponentRenderer={LogSubcomponent}
          />
        </DisplayDialog>

        <DisplayDialog
          title={
            `${this.state.currentShop.shopName} Other Users` || 'Other Users'
          }
          isOpen={this.state.showOtherUsersTableModal}
          onDismiss={this.handleHideTableModal}
        >
          <GenericTable
            id="OTHER_USERS-table"
            data={this.state.currentShop.otherShopUsers}
            filterKeys={[
              'email',
              'firstName',
              'lastName',
              'shopUserRole',
              'shopUserState',
              'userID',
              'userName',
              'userState',
            ]}
            columnDefs={this.state.userTableColumns}
            defaultSorted={[
              {
                id: 'userName',
                desc: false,
              },
            ]}
            savedColumnsId="otherUsersTableColumns"
          />
        </DisplayDialog>

        <DisplayDialog
          title={
            `${this.state.currentShop.shopName} Transactions` || 'Transactions'
          }
          isOpen={this.state.showTransactionsTableModal}
          onDismiss={this.handleHideTableModal}
        >
          <GenericTable
            id="TRANSACTIONS-table"
            data={currentShopTransactions}
            filterKeys={['amount', 'id', 'status', 'time', 'type', 'paymentMethod']}
            columnDefs={[
              {
                expander: true,
                Header: '',
                accessor: 'expander',
                Expander: ({ isExpanded, ...row }: any) => {
                  if (row.original.type === 'paymentRefund') {
                    return null;
                  } else {
                    return (
                      <div>
                        <i
                          className={`fa fa-chevron-right ${
                            isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
                          }`}
                          aria-hidden="true"
                        ></i>
                      </div>
                    );
                  }
                },
              },
              {
                Header: 'ID',
                accessor: 'id',
                maxWidth: 120,
                Cell: (row: any) => {
                  let braintreeUrl =
                    this.state.config.braintreeMode === 'sandbox'
                      ? `https://sandbox.braintreegateway.com/merchants/${this.state.config.merchantId}/transactions/${row.original.id}`
                      : `https://braintreegateway.com/merchants/${this.state.config.merchantId}/transactions/${row.original.id}`;
                  return (
                    <a
                      href={braintreeUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="u-font-mono"
                    >
                      {row.original.id}
                    </a>
                  );
                },
              },
              ...newTransCol,
              isAdvancedRole && {
                Header: 'Actions',
                accessor: 'id',
                minWidth: 80,
                maxWidth: 80,
                Cell: (row: any) => {
                  // If the transaction was involved in any kind of refund, don't give it a refund action
                  if (
                    row.original.type === 'paymentRefund' ||
                    row.original.refundedTo ||
                    row.original.refundedFrom
                  ) {
                    let label = row.original.refundedTo
                      ? 'Refunded To'
                      : row.original.refundedFrom
                      ? 'Refunded From'
                      : null;
                    let refundedDestination =
                      row.original.refundedTo || row.original.refundedFrom;
                    let braintreeUrl =
                      this.state.config.braintreeMode === 'sandbox'
                        ? `https://sandbox.braintreegateway.com/merchants/${this.state.config.merchantId}/transactions/${refundedDestination}`
                        : `https://braintreegateway.com/merchants/${this.state.config.merchantId}/transactions/${refundedDestination}`;
                    if (!refundedDestination) {
                      return (
                        <button
                          className="c-btn-icon"
                          onClick={this.handleViewReceipt.bind(this, row.original.id)}
                        >
                          <div className="c-btn__inner">
                            <span
                              className="c-btn__icon fa fa-receipt"
                              title={TextOnly('viewReceipt')}
                            />
                          </div>
                        </button>
                      );
                    }

                    return (
                      <>
                        <button
                          className="c-btn-icon"
                          onClick={this.handleViewReceipt.bind(this, row.original.id)}
                        >
                          <div className="c-btn__inner">
                            <span
                              className="c-btn__icon fa fa-receipt"
                              title={TextOnly('viewReceipt')}
                            />
                          </div>
                        </button>
                        <GenericPopover
                          anchorElement={
                            <button className="c-btn-icon">
                              <div className="c-btn__inner">
                                <span
                                  className="c-btn__icon fa fa-info-circle"
                                  title="Info"
                                />
                              </div>
                            </button>
                          }
                          body={
                            <>
                              <strong>{label}: </strong>
                              {
                                <a
                                  href={braintreeUrl}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {refundedDestination}
                                </a>
                              }
                            </>
                          }
                          color="#0088cc"
                          position="top"
                          align="end"
                          arrowSize={5}
                          closeOnClick={true}
                          closeBtn={true}
                        />
                      </>
                    );
                  }
                  return (
                    <>
                      <button
                        className="c-btn-icon"
                        onClick={this.handleViewReceipt.bind(this, row.original.id)}
                      >
                        <div className="c-btn__inner">
                          <span
                            className="c-btn__icon fa fa-receipt"
                            title={TextOnly('viewReceipt')}
                          />
                        </div>
                      </button>
                      <RefundTransaction
                        shopID={this.state.currentShop.shopID}
                        transaction={row.original}
                        handleRefundTransaction={this.handleRefundTransaction}
                      />
                    </>
                  );
                },
              },
            ]}
            defaultSorted={[
              {
                id: 'time',
                desc: true,
              },
            ]}
            savedColumnsId="transactionsTableColumns"
            subComponentRenderer={TransactionSubComponent}
          />
        </DisplayDialog>

        <Dialog
          isOpen={this.state.showReceiptModal}
          onDismiss={this.handleCancelReceiptModal.bind(this)}
          className="c-modal"
          aria-label={TextOnly('autoAuthReceipt')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelReceiptModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <div className="c-modal__body">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '9rem',
              }}
            >
              <Document
                file={this.state.currentReceipt?.data || null}
                loading={TextOnly('loading')}
              >
                <Page pageNumber={1} scale={this.state.pdfScale} />
              </Document>
            </div>
            <div>
              <button
                className="c-btn-outline"
                onClick={this.handleCancelReceiptModal.bind(this)}
              >
                Dismiss
              </button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}
