import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import FadeIn from 'react-fade-in';
import Cards from 'react-credit-cards';
import _ from 'underscore';
import { Dialog } from '@reach/dialog';
import { toast } from 'react-toastify';

import ShopLogStandalone from './ShopLogStandalone';

import Accordion from '../../components/Accordion';
import AddressForm from '../../components/AddressForm';
import Chip from '../../components/Chip';
import DisplayDialog from '../../components/DisplayDialog';
import FieldWithHistory from '../../components/FieldWithHistory';
import FloatingButton from '../../components/FloatingButton';
import GenericPopover from '../../components/GenericPopover';
import GenericTable from '../../components/GenericTable';
import { Header } from '../../components/Header';
import { Led } from '../../components/Led';
import LoaderButton from '../../components/LoaderButton';
import { OptionType } from '../../components/ReactSelect/Option';
import CopyData from '../../components/CopyData';
import { TextOnly } from '../../components/Text';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


import {
  customerIdValidate,
  formatTimezone,
  generateFilterRegex,
  usernameValidate,
  uuidValidate,
  checkFilter,
  buildReceiptPdf,
  scalePdf,
} from '../../libs/utils';

import {
  getUserSummary,
  getShopSummary,
  getShops,
  getShopSummaryByProperty,
  getSystemConfig,
  removeToolFromShop,
  refundTransaction,
  terminateShop,
  downgradeShop,
  updateShopState,
  getOEMRegionList,
  removeOEMFromShop,
} from '../../libs/db-lib';

import {
  createAddressInfoCard,
  createShopStateInfoCard,
  createUserInfoCard,
  createPaymentInfoCard,
} from '../../libs/ui-constructors';

import {
  shopToolColumns,
  otherUsersColumns,
  transactionsColumns,
} from '../../libs/tables/columnDefinitions';

import {
  formatPhoneNumber,
  getCardIssuer,
  getRecentSearches,
  setRecentSearches,
  storeClipboard,
  getCreditCardString,
  getLocalStorageShopNames
} from '../../libs/utils-ts';

import {
  AddressQuery,
  ChildProps,
  RuntimeConfig,
  Shop,
  ShopOwner,
  ShopName,
  Log,
  RefundObj,
} from '../../types';
import { LogSubcomponent, TransactionSubComponent } from '../../libs/tables/subComponents';
import HelpTooltip from '../../components/Tooltips/HelpTooltip';
import RefundTransaction from '../../components/RefundTransaction';

interface ShopNames {
  timeReceived: number;
  shopNames: ShopName[];
}

interface GetShopSummaryState {
  oemInfo: {},
  oemRegionList: any[],
  queryType: string;
  query: string;
  queryError: string;
  helpText: string;
  address: AddressQuery;
  isLoading: boolean;
  isLoadingLogs: boolean;
  startDate: Moment;
  endDate: Moment;
  queriedShops: Shop[];
  queriedShopsFiltered: Shop[];
  shopNames: ShopName[];
  filter: string;
  currentShop: Shop;
  currentShopId: string;
  showShopInfo: boolean;
  showToolTableModal: boolean;
  showUsersTableModal: boolean;
  showTransactionsTableModal: boolean;
  showReceiptModal: boolean;
  showConfirmTerminateModal: boolean;
  showConfirmDowngradeModal: boolean;
  showConfirmRemoveOEMModal: boolean;
  confirmShopName: string;
  config: RuntimeConfig;
  windowWidth: number;
  baseURL: string;
  tabset: string;
  logMap: { [k: string]: Log[] };
  groupedLogMap: { [k: string]: Log };
  showLog: boolean;
  selectedActionCodes: OptionType[];
  showShopIDCol: boolean;
  showActionCodeCol: boolean;
  loadingShopNames: boolean;
  loadingAddedBy?: string;
  shopNameElasticSearchResults: string[];
  currentReceipt: any;
  pdfScale: any;
  oemToRemove: string;
  refundObj: RefundObj;
  userTableColumns: any[];
}

let state: GetShopSummaryState = {
  queryType: 'SHOP ID',
  query: '',
  queryError: '',
  helpText: '',
  address: { country: 'US', state: 'AL' } as AddressQuery,
  isLoading: false,
  isLoadingLogs: false,
  currentShop: {} as Shop,
  currentShopId: '',
  queriedShops: [] as Shop[],
  queriedShopsFiltered: [] as Shop[],
  shopNames: [] as ShopName[],
  filter: '',
  config: {
    baseUrl: '',
    braintreeMode: '',
    merchantId: '',
  },
  windowWidth: 0,
  baseURL: '',
  startDate: moment.utc().subtract(30, 'days').startOf('day'),
  endDate: moment.utc().endOf('day'),
  tabset: 'SINGLE',
  logMap: {} as { [k: string]: Log[] },
  groupedLogMap: {} as { [k: string]: Log },
  selectedActionCodes: [] as OptionType[],
  showLog: false,
  showShopIDCol: true,
  showActionCodeCol: true,
  loadingShopNames: false,
  showShopInfo: false,
  showToolTableModal: false,
  showUsersTableModal: false,
  showTransactionsTableModal: false,
  showReceiptModal: false,
  currentReceipt: null,
  pdfScale: scalePdf(window.screen.width),
  showConfirmTerminateModal: false,
  showConfirmDowngradeModal: false,
  showConfirmRemoveOEMModal: false,
  confirmShopName: '',
  oemRegionList: [] as any[],
  oemInfo: {},
  oemToRemove: '',
  shopNameElasticSearchResults: [] as string[],
  refundObj: {},
  userTableColumns: [] as any[],
};

const topRef = React.createRef<HTMLDivElement>();
let refundAmount = '-0.01';

export default class GetShopSummary extends Component<
  ChildProps,
  GetShopSummaryState
> {
  constructor(props: ChildProps) {
    super(props);
    const searchParams = new URLSearchParams(window.location.search);

    const query = searchParams.get('query');
    const queryType = searchParams.get('queryType');
    if(query && queryType) {
      state.query = query;
      state.queryType = queryType;
    }
    // This will initial state on first time, but stores previous state
    // when navigating back to the page
    this.state = state;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.location !== prevProps.location) {
      // URL has changed
      this.checkURLChange();
    }
  }

  checkURLChange() {
    const searchParams = new URLSearchParams(window.location.search);
    const query = (searchParams.get('query'))?.replace(/ /g, '+');
    const queryType = searchParams.get('queryType');
    const shopID = localStorage.getItem('shopID');
    if(query && queryType) {
      this.setState({
        query,
        queryType,
      });
      this.handleSubmit(undefined, query, queryType);
    } else if (shopID) {
      this.setState({
        query: shopID,
        queryType: 'SHOP ID'
      });
      localStorage.removeItem('shopID');
      this.handleSubmit(undefined, shopID, "SHOP ID");
    }
    this.handleHideTableModal();
  }

  async componentDidMount() {
    this.checkURLChange();
    let config = await getSystemConfig();
    const oemRegionList = await getOEMRegionList();
    const oemInfoAux = {};
    oemRegionList.forEach((element: any) => {
      oemInfoAux[element.oemID] = element.oemName;
      oemInfoAux[element.regionCode] = element.regionName;
    });
    this.setState({
      config: config,
      baseURL: config.baseUrl,
      oemRegionList: oemRegionList,
      oemInfo: oemInfoAux,
    });

    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth);
    if (Array.isArray(this.state.queriedShops)) {
      let pageData: { [k: string]: any } = {};
      this.state.queriedShops.forEach((shop, index) => {
        pageData[index] = shop;
      });
      storeClipboard(pageData);
    }
    this.setState({
      userTableColumns: otherUsersColumns(this.props.user.userTimezone),
    });

  }

  componentWillUnmount() {
    // Setting state (global file variable) to this.state allowed persistance
    // of state when navigating back to the component later
    state = {
      ...this.state,
      isLoading: false,
      showToolTableModal: false,
      showUsersTableModal: false,
      showTransactionsTableModal: false,
    };
  }

  setWindowWidth = () => {
    setTimeout(this.setStateWidth.bind(this), 20);
  };

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
    if (this.state.windowWidth <= 500 && document.querySelectorAll('.rccs')) {
      (
        document.querySelectorAll('.rccs') as NodeListOf<HTMLDivElement>
      ).forEach((element) => {
        element.style.transform = `scale(${this.state.windowWidth / 500})`;
      });
    }
  }

  handleChange = async (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | string,      // If event is from a FieldWithHistory the event will just be a string
    fieldId?: string
  ) => {
    const { queryType } = this.state;
    if (typeof event === 'string') {
      if (queryType === 'CREDIT CARD LAST 4' || queryType === 'TRANSACTION LAST 4') {
        const reg = new RegExp(/^(\s{0}|\d+)$/);
        if (!(event.length < 5 && reg.test(event))) {
          return;
        }
      }
      let error = '';
      switch (this.state.queryType) {
        case 'SHOP ID':
        case 'OWNER ID':
          error = !uuidValidate(event.trim())
            ? 'ID must be in this format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
            : '';
          event = event.toLowerCase();
          break;
        case 'OWNER USERNAME':
          error = !usernameValidate(event.trim())
            ? 'Username must be at least 8 alphanumeric lowercase characters starting with a letter'
            : '';
          event = event.toLowerCase();
          break;
        case 'CUSTOMER ID':
          error = !customerIdValidate(event.trim())
            ? `Customer ID must be integer characters`
            : '';
          break;
      }
      this.setState({
        [fieldId as any]: event,
        queryError: error,
      } as any);
    } else if (event) {
      const { id, value } = event.target;

      this.setState({
        [id]: value,
      } as any);
    }
  };

  handleQueryTypeSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    let helpText = '' as string | JSX.Element;
    switch (e.target.value) {
      case 'SHOP NAME':
        helpText =
          <ul>
            <li>
              {'SHOP NAME is a case-insensitive "contains" query, and can be ' +
                'partially matched (i.e., "T", "Te", "Tes" or "Test" will match "Test Auto Shop").'}
            </li>
            <li>
              {'You can also use NOT which applies to the whole string following ' +
                '(i.e. "NOT auto center" will match "Test Auto" but not "Test Auto Center").'}
            </li>
            <li>
              {'You can also use "&&" and "||" to make combination queries ' +
                '(i.e. "test && center" will match "Test Auto Center" but not "Test Shop".' +
                ' "test || center" will match "Testing Shop" and "Auto Center").'}
            </li>
            <li>
              {'You can also use "NOT" and "&&" and "||" in combination ' +
                '(i.e. "NOT test && center" will match "First Auto Center" but not "Test Auto Center".' +
                ' "NOT test || center" will match "First Auto Center" and "First Auto" but not "Test Auto").'}
            </li>
          </ul>
        // Load shop names from localstorage or backend
        this.setState({
          loadingShopNames: true
        } as any);
        let self = this;
        const flag = false;
        getLocalStorageShopNames(flag, (err: Error, finalResult: any) => {
          self.setState({
            shopNames: finalResult,
            loadingShopNames: false
          });
        });

        break;
      case 'PHONE NUMBER':
        helpText = (
          <section>
            <p>
              PHONE NUMBER is a "contains" query that ignores non-digit
              characters, and can be partially matched.
            </p>
            <p>
              For example, the following queries will all match to the number:{' '}
              <strong>+11234567890</strong>
            </p>
            <pre>(123) 456-7890</pre>
            <pre>123-456-7890</pre>
            <pre>123.456.7890</pre>
            <pre>34567</pre>
            <pre>3.456.7</pre>
            <p>
              Note that "34567" and "3.456.7" could match to multiple numbers,
              such as <strong>+11234567890</strong>,{' '}
              <strong>+18934567123</strong>, etc.
            </p>
          </section>
        );
        break;
      case 'ADDRESS':
        helpText =
          'ADDRESS fields are case-sensitive "contains" queries, and can be partially matched. No field is required upon submission except Country.';
        break;
    }
    this.setState({
      queryType: e.target.value,
      query: '',
      queryError: '',
      helpText: helpText,
    } as any);
  };

  handleRangeChange = (value: any) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    });
  };

  handleAddressChange = (addressObj: AddressQuery) => {
    this.setState({
      address: addressObj,
    });
  };

  handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter: e.target.value,
    });

    const updateFilter = _.debounce((f: string) => {
      this.updateFilteredList(f);
    }, 300);

    updateFilter(e.target.value);
  };

  updateFilteredList = (filter: string) => {
    let shopList = this.state.queriedShops;

    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);
      shopList = this.state.queriedShops.filter((u) => {
        if (regexStr.test(u.shopName) || regexStr.test(u.shopID)) {
          return true;
        }
        return false;
      });
    }
    this.setState({
      queriedShopsFiltered: shopList || [],
    });
  };

  validateForm() {
    const { query, queryType } = this.state;

    switch (queryType) {
      case 'SHOP NAME':
        return this.state.shopNames && query.trim().length > 0;
      case 'SHOP ID':
      case 'OWNER ID':
        return uuidValidate(query.trim());
      case 'CUSTOMER ID':
        return customerIdValidate(query.trim());
      case 'OWNER USERNAME':
        return usernameValidate(query.trim());
      case 'ADDRESS':
        return Object.values(this.state.address as Object).some(
          (field) => field !== ''
        );
      case 'CREDIT CARD LAST 4':
        return query.trim().length === 4;
      case 'TRANSACTION LAST 4':
        return query.trim().length === 4;
      default:
        return query.trim().length > 0;
    }
  }

  handleSubmit = async (
    e?: React.SyntheticEvent,
    query?: string,
    queryType?: string
  ) => {
    e?.preventDefault();

    this.setState({ isLoading: true, showLog: false });
    if(!queryType){
      queryType = this.state.queryType;
    }
    if(!query){
      query = this.state.query;
    }

    try {
      let result: any = null;
      let user: any = null;

      switch (queryType) {
        case 'SHOP ID':
          result = await getShopSummary(query);
          break;
        case 'SHOP NAME':
          const shopIDs = this.state.shopNames.filter((shop) => checkFilter(query, shop.shopName)).map((shop) => shop.shopID);
          if (shopIDs.length < 100) {
            result = await getShops(shopIDs);
          } else {
            result = 'Too many results (' + shopIDs.length + ').  Please be more specific.';
          }
          // If there's only one result, just get the full summary and logs
          if (result.length === 1) {
            result = await getShopSummary(result[0].shopID);
            result = [result];
          }
          break;
        case 'CREDIT CARD LAST 4':
        case 'TRANSACTION LAST 4':
        case 'PHONE NUMBER':
        case 'CUSTOMER ID':
          if (queryType === 'PHONE NUMBER' && query) {
            query = formatPhoneNumber(query);
          }
          result = await getShopSummaryByProperty(queryType, query);

          // If there's only one result, just get the full summary and logs
          if (result.length === 1) {
            result = await getShopSummary(result[0].shopID);
            result = [result];
          }
          break;
        case 'OWNER ID':
        case 'OWNER USERNAME':
          if (queryType === 'OWNER USERNAME') query = query?.toLowerCase();
          let apiQueryType = queryType === 'OWNER ID' ? 'USERID' : 'USERNAME';
          user = await getUserSummary(apiQueryType, query);

          if (user?.error === 'User record not found!') {
            this.props.handleShowAlert(
              'Error',
              'This user was not found, or does not exist!'
            );
            return;
          }

          let shop = user.userShops.find(
            (shop: Shop) => shop.shopUserRole === 'OWNER'
          );

          if (shop) {
            result = await getShopSummary(shop.shopID);
          } else {
            this.props.handleShowAlert(
              'Error',
              'This user is not the OWNER of any shops.'
            );
            return;
          }
          break;
        case 'ADDRESS':
          result = await getShopSummaryByProperty(
            'ADDRESS',
            this.state.address
          );
          break;
        default:
          this.props.handleShowAlert(
            'Error',
            `This query type is not valid: ${this.state.queryType}.`,
            false
          );
          break;
      }

      if (!result || Object.hasOwn(result, 'error')) {
        if ([401, 403].indexOf(result.error.status) === -1) {
          this.props.handleShowAlert(
            'Error',
            <span>
              {`Could not find a shop using a ${queryType} for this query: `}
              <strong>{query}</strong>
            </span>,
            false
          );
        }
        this.setState({
          showShopInfo: false,
          queriedShops: [],
          queriedShopsFiltered: [],
        });
        return;
      } else if (typeof result === 'string') {
        this.props.handleShowAlert(
          'Error',
          <span>
            {result}
            <strong>{query}</strong>
          </span>,
          false
        );

        this.setState({
          showShopInfo: false,
          queriedShops: [],
          queriedShopsFiltered: [],
        });
        return;
      } else if (result.length === 0) {
        this.props.handleShowAlert(
          'Error',
          <span>
            {`Could not find a shop using a ${queryType} for this query: `}
            <strong>{query}</strong>
          </span>,
          false
        );
        this.setState({
          showShopInfo: false,
          queriedShops: [],
          queriedShopsFiltered: [],
        });
      } else {
        if (
          queryType === 'SHOP ID' ||
          queryType === 'OWNER ID' ||
          queryType === 'OWNER USERNAME'
        ) {
          result = [result];
        }

        if (Array.isArray(result)) {
          let pageData: { [k: string]: any } = {};
          result.forEach((shop, index) => {
            pageData[index] = shop;
          });
          storeClipboard(pageData);
        }

        this.setState({
          queriedShops: result,
          queriedShopsFiltered: result,
          showShopInfo: true,
        });
      }
    } catch (e: any) {
      if (!e || Object.hasOwn(e, 'error')) {
        if ([401, 403].indexOf(e.error.status) === -1) {
          this.props.handleShowAlert(
            'Error',
            <span>
              {`Could not find a shop using a ${queryType} for this query: `}
              <strong>{query}</strong>
            </span>,
            false
          );
        } else {
          return;
        }
      }
      console.log(e.message);
      this.setState({
        showShopInfo: false,
        queriedShops: [],
        queriedShopsFiltered: [],
      });
    } finally {
      this.setState({
        isLoading: false,
      });

      let recentSearches = getRecentSearches(queryType);

      if (recentSearches) {
        if (recentSearches.length > 4) recentSearches.pop();
        if (query && recentSearches.indexOf(query) === -1) recentSearches.unshift(query);
      } else {
        recentSearches = query ? [query] : [];
      }
      setRecentSearches(queryType, recentSearches);
    }
  };

  getPlaceholder = () => {
    switch (this.state.queryType) {
      case 'SHOP ID':
      case 'OWNER ID':
        return "ex. 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'";
      case 'CUSTOMER ID':
        return this.state.config.braintreeMode === 'sandbox'
          ? "ex. 'xxxxxxxxx'"
          : "ex. 'xxxxxxxxxx'";
      case 'SHOP NAME':
        return "ex. 'John's Auto'";
      case 'OWNER USERNAME':
        return "ex. 'john.smith'";
      case 'CREDIT CARD LAST 4':
      case 'TRANSACTION LAST 4':
        return 'ex. 1234';
      case 'PHONE NUMBER':
        return 'ex. +###########, +# ### ### ####, +#(###)###-####';
    }
  };

  getQueryTypeHelper = () => {
    switch (this.state.queryType) {
      case 'SHOP ID':
      case 'OWNER ID':
      case 'OWNER USERNAME':
        return `${this.state.queryType} (lowercase)`;
      case 'ADDRESS':
        return `${this.state.queryType} (case-sensitive)`;
      case 'SHOP NAME':
      case 'PHONE NUMBER':
        return this.state.queryType;
    }
  };

  handleRemoveTool = async (shopID: string, toolID: string) => {
    try {
      await removeToolFromShop(shopID, toolID);
      this.props.handleShowAlert('Success', 'Tool has been removed!');
      let newShopTools = this.state.currentShop.shopTools?.filter(
        (tool) => tool.toolID !== toolID
      );
      let newShop = this.state.currentShop;
      newShop.shopTools = newShopTools;
      newShop.shopToolsCount = newShop.shopToolsCount - 1;

      let newShopsArr = this.state.queriedShopsFiltered.map((shop) => {
        if (shop.shopID === shopID) {
          return newShop;
        }
        return shop;
      });
      this.setState({
        currentShop: newShop,
        queriedShopsFiltered: newShopsArr,
      });
    } catch (e) {
      if (Object.hasOwn(e, 'error')) {
        if ([401, 403].indexOf(e.error.status) === -1) {
          this.props.handleShowAlert(
            'Error',
            'Tool could not be removed: ' + e
          );
        }
        return;
      }
    }
  };

  handleRefundTransaction = async (transactionID: string, shopID: string, amount: string, refundDetails?: RefundObj) => {
    const response = await refundTransaction(transactionID, shopID, amount, refundDetails);

    if (response.success) {
      this.props.handleShowAlert('Success', response.success);
      const newTransaction = response.result;
      let updatedShopTransactions = this.state.currentShop.shopPaymentInfo?.transactions?.map((transaction) => {
        if (transaction.id === transactionID) {
          let updatedTransaction = { ...transaction };
          if (transactionID === newTransaction.id) {
            updatedTransaction.status = newTransaction.status;
          } else {
            updatedTransaction.refundedTo = newTransaction.id;
          }
          updatedTransaction.refundDetails = refundDetails;
          return updatedTransaction;
        }
        return transaction;
      });

      if (transactionID !== newTransaction.id) {
        updatedShopTransactions?.unshift(newTransaction);
      }

      let newShop = this.state.currentShop;
      newShop.shopPaymentInfo.transactions = updatedShopTransactions;
      newShop.transactionsCount = updatedShopTransactions.length;

      let newShopsArr = this.state.queriedShopsFiltered.map((shop) => {
        if (shop.shopID === shopID) {
          return newShop;
        }
        return shop;
      });
      this.setState({
        currentShop: newShop,
        queriedShopsFiltered: newShopsArr,
      });
    } else {
      this.props.handleShowAlert('Error', response.error);
    }
  };

  handleTerminateShop = async (shopID: string) => {
    this.setState({
      isLoading: true,
    });

    try {
      let result = await terminateShop(shopID);
      if (Object.hasOwn(result, 'error')) {
        if ([401, 403].indexOf(result.error.status) !== -1) {
          return;
        }
      }
      this.props.handleShowAlert('Success', 'Shop has been terminated!');
      let newShop = this.state.currentShop;
      newShop.shopSubscriptionState = 'TERMINATED';
      newShop.shopPaymentNotificationState = 'SUBSCRIPTION_TERMINATED';

      let newShopsArr = this.state.queriedShopsFiltered.map((shop) => {
        if (shop.shopID === shopID) {
          return newShop;
        }
        return shop;
      });

      this.setState({
        currentShop: newShop,
        queriedShopsFiltered: newShopsArr,
      });
    } catch (e) {
      this.props.handleShowAlert('Error', 'Shop could not be terminated: ' + e);
    } finally {
      this.setState({
        isLoading: false,
        confirmShopName: '',
      });
      this.handleHideTerminateModal();
    }
  };

  handleDowngradeShop = async (shopID: string) => {
    this.setState({
      isLoading: true,
    });

    try {
      const result = await downgradeShop(shopID);
      // this.setState({
      //   query: shopID,
      //   queryType: 'SHOP ID',
      // })
      if (result.success) {
        const shop = this.state.currentShop;
        const transRef = shop!.shopPaymentInfo!.transactions!.find(trans => [
          "upgradePlusProration",
          "initialSubscriptionCharge",
          "subscriptionRenew"].includes(trans.type));

        const milliToDays = 1 / (1000 * 3600 * 24)
        const amount = [
          "initialSubscriptionCharge",
          "subscriptionRenew"].includes(transRef!.type) ? 150 : Number(transRef!.amount);
        const startPay = new Date(transRef!.time).getTime()
        const today = Date.now()
        const timeSinceTransaction = Math.ceil((today - startPay) * milliToDays)
        let amountRef;
        if (timeSinceTransaction >= 14) {
          const endPay = new Date(shop!.shopPaymentInfo!.nextBillingDate).getTime()
          const totalLenDays = Math.ceil((endPay - startPay) * milliToDays)
          const baseDay = Math.floor(amount * 100 / totalLenDays) / 100
          const leftDays = Math.ceil((endPay - today) * milliToDays)
          amountRef = Math.floor(leftDays * baseDay * 100) / 100;
        } else {
          amountRef = amount;
        }

        // Only refund if the transaction found is less than 6 months ago
        if (timeSinceTransaction < 6 * 30) {
          const refundDetails: RefundObj = {
            plusUpgradeRefunded: true,
          }
          if (transRef!.status !== 'voided') {
            await this.handleRefundTransaction(transRef!.id, shop.shopID, amountRef.toString(), refundDetails);
          }
          }

        await this.handleSubmit();
      }
    } catch (e) {
      this.props.handleShowAlert('Error', 'Shop could not be downgraded: ' + e);
    } finally {
      this.setState({
        isLoading: false,
        confirmShopName: '',
      });
      this.handleHideDowngradeModal();
    }
  };

  handleRemoveOEM = async (shopID: string, oemID: string) => {
    this.setState({
      isLoading: true,
    });

    try {
      const result = await removeOEMFromShop(shopID, oemID);
      const shop = this.state.currentShop;

      if (result.success) {
        const transRef = shop!.shopPaymentInfo!.transactions!.find(trans => {
          return (trans.type === "upgradeOEMRegionProration" &&
           trans.addedOems?.includes(oemID)) ||
          ["initialSubscriptionCharge", "subscriptionRenew"].includes(trans.type)
        });


        const milliToDays = 1 / (1000 * 3600 * 24)
        const amount = [
          "initialSubscriptionCharge",
          "subscriptionRenew"].includes(transRef!.type) ? 50 :
            transRef!.addedOems!.length === 1 ? Number(transRef!.amount) :
            Number(transRef!.amount) / transRef!.addedOems!.length;
        const startPay = new Date(transRef!.time).getTime()
        const today = Date.now()
        const timeSinceTransaction = Math.ceil((today - startPay) * milliToDays)
        let amountRef;
        if (timeSinceTransaction >= 14) {
          const endPay = new Date(shop!.shopPaymentInfo!.nextBillingDate).getTime()
          const totalLenDays = Math.ceil((endPay - startPay) * milliToDays)
          const baseDay = Math.floor(amount * 100 / totalLenDays) / 100
          const leftDays = Math.ceil((endPay - today) * milliToDays)
          amountRef = Math.floor(leftDays * baseDay * 100) / 100;
        } else {
          amountRef = amount
        }
        amountRef = Number.parseFloat(amountRef).toFixed(2);

        // Only refund if the transaction found is less than 6 months ago
        if (timeSinceTransaction < 6 * 30) {
          const refundDetails: RefundObj = {
            oemIDRefunded: oemID,
          }
          if (transRef!.status !== 'voided') {
            await this.handleRefundTransaction(transRef!.id, shop.shopID, amountRef, refundDetails);
          }
        }

        await this.handleSubmit();
      }
    } catch (e) {
      this.props.handleShowAlert('Error', 'OEM could not be removed: ' + e);
    } finally {
      this.setState({
        isLoading: false,
        confirmShopName: '',
      });
      this.handleHideRemoveOEMModal();
    }
  };

  handleUpdateShopState = async (shopID: string, state: string) => {
    this.setState({
      isLoading: true,
    });

    try {
      let result = await updateShopState(shopID, state);
      if (Object.hasOwn(result, 'error')) {
        if ([401, 403].indexOf(result.error.status) !== -1) {
          return;
        }
      }
      this.props.handleShowAlert(
        'Success',
        `Shop has been ${state === 'SUSPENDED' ? 'suspended!' : 'reinstated!'}`
      );

      let newShopsArr = this.state.queriedShopsFiltered.map((shop) => {
        if (shop.shopID === shopID) {
          let newShop = shop;

          newShop.shopState = state;
          return newShop;
        }
        return shop;
      });

      this.setState({
        queriedShopsFiltered: newShopsArr,
      });
    } catch (e) {
      this.props.handleShowAlert('Error', 'Shop could not be updated: ' + e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleShowTableModal = (modal: string, shop: Shop) => {
    let modalToShow = '';
    switch (modal) {
      case 'tools':
        modalToShow = 'showToolTableModal';
        break;
      case 'users':
        modalToShow = 'showUsersTableModal';
        break;
      case 'transactions':
        modalToShow = 'showTransactionsTableModal';
        break;
      default:
        break;
    }
    this.setState({
      [modalToShow]: true,
      currentShop: shop,
    } as any);
  };

  handleShowTerminateModal = (shop: Shop) => {
    this.setState({
      showConfirmTerminateModal: true,
      currentShop: shop,
    });
  };

  isNotDowngradable(shop: Shop) {
    return !!(
      (shop?.shopUsers?.length && shop.shopUsers.length > 5) ||
      (shop?.shopTools?.length && shop.shopTools.length > 5) ||
      (!shop?.shopPaymentInfo?.subscriptionId) ||
      (shop?.shopType !== 'PLUS') ||
      (shop?.shopSubscriptionState !== 'VALID'))

  }

  handleShowDowngradeModal = (shop: Shop) => {
    if (this.isNotDowngradable(shop)) {
      alert('Ineligible. Shop has over 5 Users/Tools, it\'s passed due or it\'s not an active subscription');
      return
    }
    this.setState({
      showConfirmDowngradeModal: true,
      currentShop: shop,
    });
  };

  handleShowRemoveOEMModal = (shop: Shop, oemID: string) => {
    this.setState({
      showConfirmRemoveOEMModal: true,
      currentShop: shop,
      oemToRemove: oemID,
    });
  };

  handleHideTableModal = () => {
    const self = this;
    document.querySelector('.c-modal-slider-75')?.classList.add('closed');
    document.querySelector('.c-modal-slider-90')?.classList.add('closed');
    setTimeout(() => {
      self.setState({
        showToolTableModal: false,
        showUsersTableModal: false,
        showTransactionsTableModal: false,
      });
    }, 350);
  };

  handleHideTerminateModal = () => {
    let self = this;
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      self.setState({
        showConfirmTerminateModal: false,
      });
    }, 350);
  };

  handleHideDowngradeModal = () => {
    let self = this;
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      self.setState({
        showConfirmDowngradeModal: false,
      });
    }, 350);
  };

  handleHideRemoveOEMModal = () => {
    let self = this;
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      self.setState({
        showConfirmRemoveOEMModal: false,
      });
    }, 350);
  };
  handleLogData = (self, shopID, logData) => {
    let newQueriedShops = self.state.queriedShops.map((shop) => {
      if (shop.shopID === shopID) {
        shop.shopLog = logData;
      }
      return shop;
    });
    let newQueriedShopsFiltered = self.state.queriedShopsFiltered.map((shop) => {
      if (shop.shopID === shopID) {
        shop.shopLog = logData;
      }
      return shop;
    })
    self.setState({
      queriedShops: newQueriedShops,
      queriedShopsFiltered: newQueriedShopsFiltered,
    });
  }

  handleViewReceipt = (transactionId, e) => {
    e.preventDefault();
    let transaction = this.state.currentShop.shopPaymentInfo.transactions.find(
      (trans) => {
        return trans.id === transactionId;
      }
    );

    let receiptDoc = buildReceiptPdf(
      transaction,
      this.state.currentShop,
      this.state.currentShop.shopUsers?.find((user => user.shopUserRole === 'OWNER')),
      this.state.oemRegionList,
    );
    this.setState({ currentReceipt: receiptDoc });
    this.setState({ showReceiptModal: true });
  };

  handleCancelReceiptModal() {
    const self = this;
    document?.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      self.setState({ showReceiptModal: false });
    }, 350);
  }

  render() {
    const {
      queriedShops,
      queriedShopsFiltered,
      showConfirmTerminateModal,
      showConfirmDowngradeModal,
      showConfirmRemoveOEMModal,
      query,
      queryType,
      queryError,
      helpText,
      windowWidth,
      config,
      currentShopId,
      currentShop,
      isLoading,
      loadingAddedBy,
      filter,
      showShopInfo,
      showToolTableModal,
      showUsersTableModal,
      showTransactionsTableModal,
      confirmShopName,
    } = this.state;

    const isAdvancedRole = ['MANAGER', 'ADMIN'].includes(
      this.props.user.userType
    );
    const isNotExternal = this.props.user.userType !== 'EXTERNAL';

    let currentShopTransactions = [] as any[];
    if (currentShop.shopPaymentInfo?.transactions) {
      currentShopTransactions = (currentShop.shopPaymentInfo?.transactions as any[])?.map(tran => {
        tran.oemNames = tran.oemIDs?.map((oemID: string) => this.state.oemInfo[oemID]);
        if (tran.addedOems && Array.isArray(tran.addedOems)) {
          tran.addedOemNames = tran.addedOems?.map((oemID: string) => this.state.oemInfo[oemID]);
        }
        if (tran.refundDetails?.oemIDRefunded) {
          tran.refundDetails.oemNameRefunded = this.state.oemInfo[tran.refundDetails.oemIDRefunded];
        }
        return tran;
      });
    }

    let shopInfoAccordions = queriedShopsFiltered
      .sort((a, b) => a.shopName.localeCompare(b.shopName))
      .map((shop: Shop, index) => {
        let shopStateCard = createShopStateInfoCard(
          shop,
          windowWidth,
          this.props.handleShowAlert
        );

        let crmStateCard = createShopStateInfoCard(
          shop,
          windowWidth,
          this.props.handleShowAlert,
          true // isCRMCard
        );

        let addressInfoCard = createAddressInfoCard(shop, windowWidth);

        let paymentInfoCard = createPaymentInfoCard(
          shop,
          windowWidth,
          config
        );

        let crmPaymentInfoCard = createPaymentInfoCard(
          shop,
          windowWidth,
          config,
          true // isCRMCard
        );

        let transactionsCount =
          shop.transactionsCount || shop.shopPaymentInfo?.transactions?.length || 0;

        let transactionsCard = windowWidth < 1220 ? (
          <div className="u-margin-bottom-large">
            <label className="mobile-display-label">
              Transactions
            </label>
            {transactionsCount > 0 ? (
              <div className="l-flex-align-stretch">
                <div className="mobile-display-value-with-button">
                  {transactionsCount}{' '}
                </div>
                <div
                  className="mobile-display-value-button"
                  onClick={() => this.handleShowTableModal('transactions', shop)}
                >
                  <i className="c-btn__icon fa fa-chevron-right u-padding-left" />
                </div>
              </div>
            ) : (
              transactionsCount
            )}
          </div>
        ) : (
          <tr className="card-tr">
            <th className="card-th">Transactions</th>
            <td className="card-td">
              {transactionsCount > 0 ? (
                <div className={"l-flex-align-center"}>
                  {transactionsCount}{' '}
                  <button
                    className="c-btn-icon"
                    onClick={() => this.handleShowTableModal('transactions', shop)}
                  >
                    <div className="c-btn__inner">
                      <i className="c-btn__icon fa fa-chevron-right" />
                    </div>
                  </button>
                </div>
              ) : (
                transactionsCount
              )}
            </td>
          </tr>
        );

        let shopOwnerInfoKeymap = {
          ID: 'ID',
          email: 'Email',
          firstName: 'First Name',
          lastName: 'Last Name',
        };

        let shopOwnerInfoCard = Object.keys(shopOwnerInfoKeymap).map((key) => {
          let value = shop.shopOwner
            ? shop.shopOwner[key as keyof ShopOwner]
            : null;
          return value ? (
            <tr className="card-tr" key={key}>
              <th className="card-th card-table-col-labels-20">
                {shopOwnerInfoKeymap[key as keyof Object]+': '}
              </th>
              <td className="card-td u-font-mono">{value}</td>
            </tr>
          ) : null;
        });

        const suspended = shop.shopState === 'SUSPENDED';
        const terminated = shop.shopState === 'TERMINATED';

        return (
          <Accordion
            title={"Shop Name: " + shop.shopName}
            key={shop.shopID}
            shopID={shop.shopID}
            collapse={currentShopId}
            accordionToggled={() => this.setState({ currentShopId: '' })}
            icon={<span className="material-icons">store</span>}
          >
            {/* Basic Info */}

            {isAdvancedRole && (
              <div className='l-flex-around-center-wrap'>
                <div className="l-flex-end  u-margin-bottom">
                  {suspended ? (
                    <GenericPopover
                      anchorElement={
                        <button
                          className="c-btn-medium c-btn-success"
                          onClick={() =>
                            this.setState({
                              currentShopId: shop.shopID,
                            })
                          }
                        >
                          <div className="c-btn__inner">
                            <i className="c-btn__icon fa fa-play" />
                            Reinstate
                          </div>
                        </button>
                      }
                      body={
                        <strong>
                          Are you sure you want to reinstate this shop?
                        </strong>
                      }
                      primaryBtnElement={
                        <LoaderButton
                          className="c-btn-medium c-btn-success"
                          disabled={false}
                          text="Yes, reinstate it"
                          loadingText="Reinstating..."
                          icon={'fa fa-play'}
                        />
                      }
                      secondaryBtnElement={
                        <button className="c-btn-medium c-btn-outline">
                          <div className="c-btn__inner">
                            <i className="c-btn__icon fal fa-times" />
                            No
                          </div>
                        </button>
                      }
                      primaryAction={ async () => {
                        return this.handleUpdateShopState(shop.shopID, 'ACTIVE');
                      }}
                      color="#fff"
                      position="top"
                      align="end"
                      arrowSize={5}
                      closeOnClick={false}
                    />
                  ) : (
                    <GenericPopover
                      anchorElement={
                        <div>
                          {!terminated ?
                            <button
                              className="c-btn-medium c-btn-warning"
                              onClick={() =>
                                this.setState({
                                  currentShopId: shop.shopID
                                })
                              }
                            >
                              <div className="c-btn__inner">
                                <i className="c-btn__icon fa fa-pause" />
                                Suspend
                              </div>
                            </button>
                            : ' '}
                        </div>
                      }
                      body={
                        <strong>
                          Are you sure you want to suspend this shop?
                        </strong>
                      }
                      primaryBtnElement={
                        <LoaderButton
                          className="c-btn-medium c-btn-warning"
                          disabled={false}
                          text="Yes, suspend it"
                          loadingText="Suspending..."
                          icon={'fa fa-pause'}
                        />
                      }
                      secondaryBtnElement={
                        <button className="c-btn-medium c-btn-outline">
                          <div className="c-btn__inner">
                            <i className="c-btn__icon fal fa-times" />
                            No
                          </div>
                        </button>
                      }
                      primaryAction={ async () => {
                        return this.handleUpdateShopState(shop.shopID, 'SUSPENDED');
                      }}
                      color="#fff"
                      position="top"
                      align="end"
                      arrowSize={5}
                      closeOnClick={false}
                    />
                  )}
                </div>
                {!terminated ?
                  <div className="l-flex-end u-margin-bottom">
                    <button
                      className="c-btn-medium c-btn-error"
                      onClick={() => {
                        this.handleShowTerminateModal(shop);
                      }}
                    >
                      <div className="c-btn__inner">
                        <i className="c-btn__icon fa fa-ban" />
                        Terminate
                      </div>
                    </button>
                  </div>
                  : ' '}
                <CopyData info={shop} />
                {(this.props.user.userType === 'ADMIN' && shop.shopType === 'PLUS') &&
                  <div className="l-flex-end u-margin-bottom">
                    <div className='l-block-text-center'>
                      <button
                        className="c-btn-medium c-btn-error"
                        onClick={() => {
                          this.handleShowDowngradeModal(shop);
                        }}
                        disabled={this.isNotDowngradable(shop)}
                      >
                        <div className="c-btn__inner">
                          <i className="c-btn__icon fa fa-thumbs-down" />
                          Downgrade
                        </div>
                      </button>
                      {!!this.isNotDowngradable(shop) &&
                        <>
                          <h3>Ineligible. Check Shop Type, Users, Tools, Subscription State, and Subscription ID</h3>
                        </>
                      }
                    </div>
                  </div>
                }
              </div>
            )}

            {/* Deskop */}
            {windowWidth > 1220 && (
              <>
                <div className="c-card u-margin-bottom-large">
                  <h2 className="c-card__title">Basic Info</h2>
                  <div className="c-card__description">
                    <table className="card-table">
                      <colgroup>
                        <col width="15%" />
                        <col />
                        <col width="15%" />
                        <col />
                      </colgroup>
                      <tbody>
                        <tr className="card-tr">
                          <th className="card-th">Shop ID: </th>
                          <td className="card-td">
                            <>
                            { queryType !== 'SHOP ID' ?
                              <button
                                className="c-btn-link-thin u-no-wrap u-font-mono width-100 display-flex"
                                onClick={() => {
                                  this.setState(
                                    {
                                      query: shop.shopID,
                                      queryType: 'SHOP ID',
                                    },
                                    () => {
                                      this.handleSubmit();
                                    }
                                  );
                                }}
                              >
                                <span className='u-font-mono'>{shop.shopID}</span>
                              </button>
                            :
                              <span className="u-font-mono">{shop.shopID}</span>
                            }
                            </>

                            <button className="c-btn-icon" onClick={() => {
                              navigator.clipboard.writeText(shop.shopID as string).then(function () {
                                toast.success('Copied!', {
                                  containerId: 'standard',
                                });
                              });
                            }}>
                              <div className="c-btn__inner">
                                <span className="c-btn__icon fa fa-clipboard" title="Copy" />
                              </div>
                            </button>
                          </td>
                          <th className="card-th">Created On: </th>
                          <td className="card-td">
                            {formatTimezone(
                              shop.createdOn,
                              this.props.user.userTimezone
                            )}
                          </td>
                        </tr>
                        <tr className="card-tr">
                          <th className="card-th">Shop Type: </th>
                          <td className="card-td">
                            <Chip
                              fontWeight="bold"
                              backgroundColor={(() => {
                                switch (shop.shopType) {
                                  case 'STANDARD':
                                    return '#556066';
                                  case 'PLUS':
                                    return '#0A4766';
                                  case 'ENTERPRISE':
                                    return '#C2000C';
                                  default:
                                    return '#0088CC';
                                }
                              })()}
                              color="#fff"
                              border="none"
                            >
                              {shop.shopType}
                            </Chip> { shop.upgradeNeeded ?
                                        <span className="u-text-warning">(Marked for Upgrade to PLUS)</span>
                                        : shop.downgradeToStandardPending ?
                                            <span className="u-text-warning">(Pending Downgrade to STANDARD)</span>
                                            : ''}
                          </td>
                          <th className="card-th">Max Users: </th>
                          <td className="card-td">{shop.shopMaxUsers}</td>
                        </tr>
                        <tr className="card-tr">
                          {shop.shopTools ? (
                            <>
                              <th className="card-th">Number of Tools: </th>
                              <td className="card-td">
                                <div className="l-flex-align-center">
                                  {shop.shopToolsCount}{' '}
                                  {shop.shopToolsCount > 0 && (
                                    <button
                                      className="c-btn-icon"
                                      onClick={() =>
                                        this.handleShowTableModal('tools', shop)
                                      }
                                    >
                                      <div className="c-btn__inner">
                                        <i className="c-btn__icon fa fa-chevron-right" />
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </td>
                            </>
                          ) : null}

                          {shop.shopUsers ? (
                            <>
                              <th className="card-th">Number of Users: </th>
                              <td className="card-td">
                                <div className="l-flex-align-center">
                                  {shop.shopUsers?.length}{' '}
                                  {shop.shopUsers?.length > 0 && (
                                    <button
                                      className="c-btn-icon"
                                      onClick={() =>
                                        this.handleShowTableModal('users', shop)
                                      }
                                    >
                                      <div className="c-btn__inner">
                                        <i className="c-btn__icon fa fa-chevron-right" />
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </td>
                            </>
                          ) : null}
                        </tr>
                        <tr className="card-tr">
                          <th className="card-th" style={{ verticalAlign: "text-top" }}>Region: </th>
                          <td className="card-td">
                          <span>{this.state.oemInfo[shop.regionCode]}</span>
                          </td>

                          <th className="card-th">Max Tech Certs: </th>
                          <td className="card-td">
                            <div className="l-flex-align-center">
                              {shop.shopMaxTechCerts ?? 0}
                            </div>
                          </td>
                        </tr>
                        <tr className="card-tr">
                          <th className="card-th" style={{ verticalAlign: "text-top" }}>OEMs: </th>
                          <td className="card-td">
                          { shop.oemIDs.length === 1 ?
                              this.state.oemInfo[shop.oemIDs[0]]
                            :
                              shop.oemIDs?.map((oemID: string) =>
                                <tr className={"u-padding-none"}>
                                  {this.props.user.userType === 'ADMIN' ?
                                    <td className={"u-padding-none"}>
                                      <button
                                        className="c-btn-icon"
                                        onClick={() =>
                                          this.handleShowRemoveOEMModal(shop, oemID)
                                        }
                                      >
                                        <span
                                          className="fal fa-circle-xmark"
                                          title="Remove OEM"
                                        />
                                      </button>
                                    </td>
                                  : '' }
                                  <td className={"u-padding-none"}>
                                    {this.state.oemInfo[oemID]} {
                                      shop.removedOemIDs?.includes(oemID) ? <span className="u-text-warning">(Pending Removal)</span> : ''
                                    }
                                  </td>
                                </tr>
                              )
                            }
                          </td>

                          {shop.shopMaxTechCerts && (
                            <>
                              <th className="card-th">Number of Used Tech Certs: </th>
                              <td className="card-td">
                                <div className="l-flex-align-center">
                                  {shop.numUsedTechCerts ?? 0}
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Shop State and Address Info */}
                <div className="l-flex-between">
                  <div>
                    <div className="c-card u-margin-right-xlarge u-margin-bottom">
                      <h2 className="c-card__title">Shop States</h2>
                      <div className="c-card__description">
                        <table className="card-table">
                          <tbody>{shopStateCard}</tbody>
                        </table>
                        {shop.hasOwnProperty('crmState') && (
                          <table className="card-table u-padding-top-xlarge">
                            <tbody>{crmStateCard}</tbody>
                          </table>
                        )}
                      </div>
                    </div>

                    <div className="c-card u-margin-right-xlarge">
                      <h2 className="c-card__title">Address Info</h2>
                      <div className="c-card__description">
                        <table className="card-table">
                          <colgroup>
                            <col />
                            <col />
                          </colgroup>
                          <tbody>{addressInfoCard}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* Subscription Info and Shop Owner Info*/}
                  {shop.shopPaymentInfo ?
                    <div>
                      <div className="c-card u-margin-left-xlarge u-margin-bottom">
                        <h2 className="c-card__title">
                          Subscription and Payment Info
                        </h2>
                        <div className="c-card__description">
                          <table className="card-table">
                            <tbody>{paymentInfoCard}</tbody>
                          </table>
                          {shop.hasOwnProperty('crmState') && (
                            <table className="card-table u-padding-top-xlarge">
                              <tbody>{crmPaymentInfoCard}</tbody>
                            </table>
                          )}
                          <table className="card-table u-padding-top-xlarge">
                            <tbody>{transactionsCard}</tbody>
                          </table>
                          <div className="u-margin-top-xlarge u-margin-bottom-large">

                            <Cards
                              cvc=""
                              expiry={
                                shop.shopPaymentInfo?.expirationDate || '00/00'
                              }
                              name=" "
                              number={
                                getCreditCardString(shop.shopPaymentInfo?.cardType)
                                + (shop.shopPaymentInfo?.last4 || '••••')
                              }
                              issuer={getCardIssuer(shop.shopPaymentInfo?.cardType || 'None')}
                              preview={true}
                            />
                          </div>
                        </div>
                      </div>

                      {shop.shopOwner ? (
                        <div className="c-card u-margin-left-xlarge">
                          <h2 className="c-card__title">Shop Owner</h2>
                          <div className="c-card__description">
                            <table className="card-table">
                              <colgroup>
                                <col />
                                <col />
                              </colgroup>
                              <tbody>{shopOwnerInfoCard}</tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    :
                    <div>
                      <div className="c-card u-margin-left-xlarge u-margin-bottom">
                        <h2 className="c-card__title">
                          Subscription and Payment Info
                        </h2>
                        <h2>There is no Subscription or Payment Info</h2>
                        <div className="u-margin-top-xlarge u-margin-bottom-large"></div>
                      </div>
                    </div>
                  }
                </div>
                <hr />
                {
                  <ShopLogStandalone
                    shop={shop}
                    user={this.props.user}
                    handleShowAlert={this.props.handleShowAlert}
                    index={index}
                    onChange={ (shopID, shopLog) => {
                      const self = this;
                      this.handleLogData(self, shopID, shopLog);
                    }}
                    oemInfo={this.state.oemInfo}
                  />
                }
              </>
            )}

            {/* Mobile */}
            {windowWidth < 1220 && (
              <>
                <div className="c-card u-margin-bottom-large">
                  <h2 className="c-card__title">Basic Info</h2>
                  <div className="c-card__description">
                    <div className="u-margin-bottom-large">
                      <label className="mobile-display-label">Shop ID</label>
                      <div className="mobile-display-value u-font-mono">
                        {[
                          'SHOP NAME',
                          'CREDIT CARD LAST 4',
                          'TRANSACTION LAST 4',
                          'ADDRESS',
                          'PHONE NUMBER',
                        ].indexOf(queryType) !== -1 &&
                          queriedShops.length !== 1 ? (
                          <button
                            className="c-btn-link-thin"
                            onClick={() => {
                              this.setState(
                                {
                                  query: shop.shopID,
                                  queryType: 'SHOP ID',
                                },
                                () => {
                                  this.handleSubmit();
                                }
                              );
                            }}
                          >
                            {shop.shopID.toLowerCase()}
                          </button>
                        ) : (
                          <span>{shop.shopID}</span>
                        )}
                      </div>
                    </div>

                    <div className="u-margin-bottom-large">
                      <label className="mobile-display-label">Max Users</label>
                      <div className="mobile-display-value">
                        {shop.shopMaxUsers}
                      </div>
                    </div>

                    <div className="u-margin-bottom-large">
                      <label className="mobile-display-label">Shop Type</label>
                      <div className="mobile-display-value">
                        <Chip
                          fontWeight="bold"
                          backgroundColor={(() => {
                            switch (shop.shopType) {
                              case 'STANDARD':
                                return '#556066';
                              case 'PLUS':
                                return '#0A4766';
                              case 'ENTERPRISE':
                                return '#C2000C';
                              default:
                                return '#0088CC';
                            }
                          })()}
                          color="#fff"
                          border="none"
                        >
                          {shop.shopType}
                        </Chip>
                      </div>
                    </div>

                    <div className="u-margin-bottom-large">
                      <label className="mobile-display-label">Region:</label>
                      <div className="mobile-display-value">
                        <div><span >{this.state.oemInfo[shop.regionCode]}</span><br /></div>
                      </div>
                    </div>

                    <div className="u-margin-bottom-large">
                      <label className="mobile-display-label">OEMs:</label>
                      <div className="mobile-display-value">
                        {shop.oemIDs?.map((oemID: string)=> this.state.oemInfo[oemID]).join(", ") }
                      </div>
                    </div>

                    <div className="u-margin-bottom-large">
                      <label className="mobile-display-label">Created On</label>
                      <div className="mobile-display-value">
                        {formatTimezone(
                          shop.createdOn,
                          this.props.user.userTimezone
                        )}
                      </div>
                    </div>

                    {isNotExternal && shop.shopTools && (
                      <div className="u-margin-bottom-large">
                        <label className="mobile-display-label">
                          Number of Tools
                        </label>
                        <div className="l-flex-align-stretch">
                          <div
                            className={
                              shop.shopToolsCount > 0
                                ? 'mobile-display-value-with-button'
                                : 'mobile-display-value'
                            }
                          >
                            {shop.shopToolsCount}{' '}
                          </div>
                          {shop.shopToolsCount > 0 && (
                            <div
                              className="mobile-display-value-button"
                              onClick={() =>
                                this.handleShowTableModal('tools', shop)
                              }
                            >
                              <i className="c-btn__icon fa fa-chevron-right u-padding-left" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {shop.shopUsers && (
                      <div className="u-margin-bottom-large">
                        <label className="mobile-display-label">
                          Number of Users
                        </label>
                        <div className="l-flex-align-stretch">
                          <div
                            className={
                              shop.shopUsers?.length > 0
                                ? 'mobile-display-value-with-button'
                                : 'mobile-display-value'
                            }
                          >
                            {shop.shopUsers?.length}{' '}
                          </div>
                          {shop.shopUsers?.length > 0 && (
                            <div
                              className="mobile-display-value-button"
                              onClick={() =>
                                this.handleShowTableModal('users', shop)
                              }
                            >
                              <i className="c-btn__icon fa fa-chevron-right u-padding-left" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Shop State and Address Info */}
                <div className="c-card u-margin-bottom-large">
                  <h2 className="c-card__title">Shop States</h2>
                  <div className="c-card__description">{shopStateCard}</div>
                </div>

                <div className="c-card u-margin-bottom-large">
                  <h2 className="c-card__title">Address Info</h2>
                  <div className="c-card__description">{addressInfoCard}</div>
                </div>
                {/* Subscription Info and Shop Owner Info*/}
                <div className="c-card u-margin-bottom-large">
                  <h2 className="c-card__title">
                    Subscription and Payment Info
                  </h2>
                  <div className="c-card__description">
                    {paymentInfoCard}
                    <div className="l-flex-center u-margin-top-xlarge u-margin-bottom-large">
                      <Cards
                        cvc=""
                        expiry={shop.shopPaymentInfo?.expirationDate || '00/00'}
                        name=" "
                        number={
                          getCreditCardString(shop.shopPaymentInfo.cardType) +
                          (shop.shopPaymentInfo?.last4 || '••••')
                        }
                        issuer={getCardIssuer(shop.shopPaymentInfo?.cardType || 'None')}
                        preview={true}
                      />
                    </div>
                  </div>
                </div>
                {shop.shopOwner ? (
                  <div className="c-card u-margin-bottom-large">
                    <h2 className="c-card__title">Shop Owner</h2>
                    <div className="c-card__description">
                      <table className="card-table">
                        <colgroup>
                          <col />
                          <col />
                        </colgroup>
                        <tbody>{shopOwnerInfoCard}</tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <hr />
                {
                  <ShopLogStandalone
                    shop={shop}
                    user={this.props.user}
                    handleShowAlert={this.props.handleShowAlert}
                    index={index}
                    onChange={ (shopID, shopLog) => {
                      const self = this;
                      this.handleLogData(self, shopID, shopLog);
                    }}
                    oemInfo={this.state.oemInfo}
                  />
                }
              </>
            )}
          </Accordion>
        );
      });

    return (
      <>
        <Header context="Shop Functions" title="Get Shop Summary" />
        <FloatingButton
          onClick={() => {
            if (topRef && topRef.current) {
              topRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }
          }}
        />
        <div className="l-container" ref={topRef}>
          <form>
            <div className="c-field">
              <label htmlFor="queryType" className="c-field__label">
                Query Type
              </label>
              <div className="c-select">
                <select
                  onChange={this.handleQueryTypeSelectionChange}
                  id="queryType"
                  value={queryType}
                >
                  {[
                    'SHOP ID',
                    'SHOP NAME',
                    'OWNER ID',
                    'OWNER USERNAME',
                    'CUSTOMER ID',
                    'CREDIT CARD LAST 4',
                    'TRANSACTION LAST 4',
                    'ADDRESS',
                    'PHONE NUMBER',
                  ].map((type, i) => {
                    return <option key={i}>{type}</option>;
                  })}
                </select>
              </div>
            </div>

            {queryType !== 'ADDRESS' ? (
              <>
                <div
                  className={`c-field ${queryError && 'u-margin-bottom-none'}`}
                >
                  <label
                    htmlFor="query"
                    className="l-flex-align-center c-field__label u-margin-top-large"
                  >
                    {this.getQueryTypeHelper()}
                    {helpText && (
                      <small>
                        <HelpTooltip
                          label={helpText}
                          additionalStyles={{ fontSize: '12px' }}
                          onClick={() =>
                            this.props.handleShowAlert &&
                            this.props.handleShowAlert('Info', helpText, false)
                          }
                        />
                      </small>
                    )}
                  </label>

                  <FieldWithHistory
                    fieldId="query"
                    searchKey={queryType}
                    selected={query}
                    onInputChange={this.handleChange}
                    onChange={this.handleChange}
                    placeholder={this.getPlaceholder()}
                    onEnter={() => {
                      if (this.validateForm()) this.handleSubmit();
                    }}
                  />
                </div>
                {queryError && (
                  <div className="u-margin-bottom-large u-text-error u-margin-left-large">
                    <small>{queryError}</small>
                  </div>
                )}
              </>
            ) : (
              <>
                {helpText && (
                  <small>
                    <HelpTooltip
                      label={helpText}
                      additionalStyles={{
                        fontSize: '12px',
                        width: 'fit-content',
                      }}
                      onClick={() =>
                        this.props.handleShowAlert &&
                        this.props.handleShowAlert('Info', helpText, false)
                      }
                    />
                  </small>
                )}
                <AddressForm handleChange={this.handleAddressChange} />
              </>
            )}
            <div className="c-field">
              <LoaderButton
                disabled={!this.validateForm() || this.state.loadingShopNames}
                isLoading={isLoading}
                text={this.state.loadingShopNames ? "Please Wait..." : "Get Summary"}
                loadingText="Searching..."
                onClick={this.handleSubmit}
              />
            </div>
            {
              queryType === 'SHOP NAME' &&
              <div className="c-field">
                <LoaderButton
                  type='button'
                  disabled={false}
                  isLoading={this.state.loadingShopNames}
                  text={this.state.loadingShopNames ? "Syncing..." : "Sync"}
                  loadingText="Syncing..."
                  onClick={() => {
                    window.indexedDB.deleteDatabase('ShopNames')
                    this.setState({
                      loadingShopNames: true,
                      shopNames: [],
                    } as any);
                    let self = this;
                    const flag = true;
                    getLocalStorageShopNames(flag, (err: Error, finalResult: any) => {
                      if (err) {
                        console.log('Error: ', err)
                      }
                      self.setState({
                        shopNames: finalResult,
                        loadingShopNames: false
                      });
                    });
                  }}
                />
              </div>
            }
          </form>
        </div>
        <FadeIn visible={!isLoading && showShopInfo}>
          {queriedShops.length > 2 && (
            <>
              <div>
                <h2>{queriedShopsFiltered.length} result(s) found:</h2>
              </div>
              <div className="c-field u-margin-top-small u-margin-right-small">
                <label
                  htmlFor="filter"
                  className="c-field__label u-is-vishidden"
                >
                  Filter
                </label>
                <input
                  type="text"
                  id="filter"
                  maxLength={50}
                  className="c-input u-font-mono"
                  placeholder="Filter (Shop Name, Shop ID)"
                  value={filter}
                  onChange={this.handleChangeFilter}
                />
                <i className="c-field__input-icon fal fa-search" />
              </div>
            </>
          )}
          <div>{shopInfoAccordions}</div>
        </FadeIn>

        {/* Tool, OtherUser, and Transactions Tables */}
        {currentShop && Object.keys(currentShop).length > 0 && (
          <>
            <DisplayDialog
              title={`${currentShop.shopName} Tools` || 'Shop Tools'}
              isOpen={showToolTableModal}
              onDismiss={this.handleHideTableModal}
              is90={true}
            >
              <GenericTable
                id="SHOP_TOOLS-table"
                data={currentShop.shopTools as any[]}
                filterKeys={[
                  'toolModel',
                  'toolManufacturer',
                  'toolSerial',
                  'addedBy',
                  'toolID'
                ]}
                columnDefs={[
                  {
                    Header: 'Manufacturer',
                    accessor: 'toolManufacturer',
                    Cell: (row: any) => {
                      const regionCode = this.state?.currentShop?.regionCode;
                      const manufacturer = row.original?.regions?.find((item:any)=>{
                        return (item?.regionCode===regionCode && !!item?.displayName)
                      })
                      if(!regionCode || !manufacturer) return <span>{row.original.toolManufacturer}</span>
                      else return <span>{manufacturer.displayName}</span>
                    },
                  },
                  ...(shopToolColumns.filter(item=>item.Header !== 'Manufacturer')),

                  ...(this.state.currentShop.shopType !== 'STANDARD' ? [
                    {
                    Header: 'Tool Name',
                    accessor: 'toolName'
                  },
                  {
                    Header: 'Tool Notes',
                    accessor: 'toolNotes',
                    Cell: (row: any) => {
                      return (
                        <ul>
                          {row.original.toolNotes?.map((note: any, idx: any)=><li key={idx}>{note}</li>)}
                        </ul>
                      );
                    },
                  }
                  ] : []),
                  {
                    Header: 'Tool Id',
                    accessor: 'toolID',
                    Cell: (row: any) => {
                      return (
                        <span className="tofw-ell">
                          {row.original.toolID}
                        </span>
                      );
                    },
                  },
                  {
                    Header: 'Added By',
                    accessor: 'addedBy',
                    Cell: (row: any) => {
                      return loadingAddedBy === row.original.toolID ? (
                        <button className="c-btn-icon">
                          <div className="c-btn__inner">
                            <i className="c-btn__icon fal fa-spinner-third spinning" />
                          </div>
                        </button>
                      ) : (
                        <button
                          className="c-btn-link-thin-chunk u-font-mono"
                          onClick={async () => {
                            this.setState({
                              loadingAddedBy: row.original.toolID,
                            });
                            let user = await getUserSummary(
                              'USERID',
                              row.original.addedBy
                            );
                            let userInfo = createUserInfoCard(
                              user,
                              windowWidth
                            );
                            let modalBody = (
                              <div className="c-card">
                                <h2 className="c-card__title">{`${user.firstName} ${user.lastName}`}</h2>
                                <div className="c-card__description">
                                  <table className="card-table">
                                    <colgroup>
                                      <col className="card-table-col-labels" />
                                      <col />
                                    </colgroup>
                                    <tbody>{userInfo}</tbody>
                                  </table>
                                </div>
                                <Led status={user.userState as any} />
                              </div>
                            );
                            this.props.handleShowAlert(
                              `User Summary`,
                              modalBody,
                              false
                            );
                            this.setState({
                              loadingAddedBy: '',
                            });
                          }}
                        >
                          {row.original.addedBy}
                        </button>
                      );
                    },
                  },
                  isAdvancedRole && {
                    Header: 'Actions',
                    accessor: 'toolModel',
                    minWidth: 80,
                    maxWidth: 80,
                    Cell: (row: any) => {
                      return (
                        <GenericPopover
                          anchorElement={
                            <button className="c-btn-icon">
                              <div className="c-btn__inner">
                                <span
                                  className="c-btn__icon fal fa-trash-alt"
                                  title="Remove Tool"
                                />
                              </div>
                            </button>
                          }
                          body={
                            <strong>
                              Are you sure you want to remove this tool?
                            </strong>
                          }
                          primaryBtnElement={
                            <LoaderButton
                              className="c-btn-medium c-btn-error"
                              disabled={false}
                              text="Yes, remove it"
                              loadingText="Removing..."
                              icon={'fal fa-trash-alt'}
                            />
                          }
                          secondaryBtnElement={
                            <button className="c-btn-medium c-btn-outline">
                              <div className="c-btn__inner">
                                <i className="c-btn__icon fal fa-times" />
                                No, not yet
                              </div>
                            </button>
                          }
                          primaryAction={ async () => {
                            return this.handleRemoveTool(
                              currentShop.shopID,
                              row.original.toolID
                            );
                          }}
                          color="#fff"
                          position="top"
                          align="end"
                          arrowSize={5}
                          closeOnClick={false}
                        />
                      );
                    },
                  },
                ]}
                defaultSorted={[
                  {
                    id: 'toolSerial',
                    desc: false,
                  },
                ]}
                savedColumnsId="toolsTableColumns"
                subComponentRenderer={LogSubcomponent}
              />
            </DisplayDialog>

            <DisplayDialog
              title={`${currentShop.shopName} Shop Users` || 'Shop Users'}
              isOpen={showUsersTableModal}
              onDismiss={this.handleHideTableModal}
              is90={true}
            >
              <GenericTable
                id="OTHER_USERS-table"
                data={currentShop.shopUsers as any[]}
                filterKeys={[
                  'email',
                  'firstName',
                  'lastName',
                  'shopUserRole',
                  'shopUserState',
                  'userID',
                  'userName',
                  'userState',
                ]}
                columnDefs={this.state.userTableColumns}
                defaultSorted={[
                  {
                    id: 'userName',
                    desc: false,
                  },
                ]}
                savedColumnsId="otherUsersTableColumns"
              />
            </DisplayDialog>

            <DisplayDialog
              title={`${currentShop.shopName} Transactions` || 'Transactions'}
              isOpen={showTransactionsTableModal}
              onDismiss={this.handleHideTableModal}
              is90={true}
            >
              <GenericTable
                id="TRANSACTIONS-table"
                data={currentShopTransactions}
                filterKeys={['amount', 'id', 'status', 'time', 'type', 'paymentMethod']}
                columnDefs={[
                  {
                    expander: true,
                    Header: '',
                    accessor: 'expander',
                    Expander: ({ isExpanded, ...row }: any) => {
                      if (row.original.type === 'paymentRefund' && !row.original.refundDetails) {
                        return null;
                      } else {
                        return (
                          <div>
                            <i
                              className={`fa fa-chevron-right ${
                                isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
                              }`}
                              aria-hidden="true"
                            ></i>
                          </div>
                        );
                      }
                    },
                  },
                  {
                    Header: 'ID',
                    accessor: 'id',
                    maxWidth: 120,
                    Cell: (row: any) => {
                      let braintreeUrl =
                      this.state.config.braintreeMode === 'sandbox'
                          ? `https://sandbox.braintreegateway.com/merchants/${config.merchantId}/transactions/${row.original.id}`
                          : `https://braintreegateway.com/merchants/${config.merchantId}/transactions/${row.original.id}`;
                      return (
                        <a
                          href={braintreeUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="u-font-mono"
                        >
                          {row.original.id}
                        </a>
                      );
                    },
                  },
                  ...transactionsColumns,
                  isAdvancedRole && {
                    Header: 'Actions',
                    accessor: 'id',
                    minWidth: 90,
                    maxWidth: 120,
                    Cell: (row: any) => {
                      // If the transaction was involved in any kind of refund, don't give it a refund action
                      if (
                        row.original.type === 'paymentRefund' ||
                        row.original.refundedTo ||
                        row.original.refundedFrom ||
                        row.original.status === 'processor_declined' ||
                        row.original.status === 'voided'
                      ) {
                        const label = row.original.refundedTo
                          ? 'Refunded To'
                          : row.original.refundedFrom
                            ? 'Refunded From'
                            : null;
                        const refundedDestinations = (row.original.refundedTo || row.original.refundedFrom)?.split(', ').map((id: string) => {
                          const url = this.state.config.braintreeMode === 'sandbox'
                            ? `https://sandbox.braintreegateway.com/merchants/${config.merchantId}/transactions/${id}`
                            : `https://braintreegateway.com/merchants/${config.merchantId}/transactions/${id}`;
                          return { id: id, url: url };
                        });

                        if (!refundedDestinations) {
                          return (
                            <button
                              className="c-btn-icon"
                              onClick={this.handleViewReceipt.bind(this, row.original.id)}
                            >
                              <div className="c-btn__inner">
                                <span
                                  className="c-btn__icon fa fa-receipt"
                                  title={TextOnly('viewReceipt')}
                                />
                              </div>
                            </button>
                          );
                        }

                        return (
                          <>
                            <button
                              className="c-btn-icon"
                              onClick={this.handleViewReceipt.bind(this, row.original.id)}
                            >
                              <div className="c-btn__inner">
                                <span
                                  className="c-btn__icon fa fa-receipt"
                                  title={TextOnly('viewReceipt')}
                                />
                              </div>
                            </button>
                            <GenericPopover
                              anchorElement={
                                <button className="c-btn-icon">
                                  <div className="c-btn__inner">
                                    <span
                                      className="c-btn__icon fa fa-info-circle"
                                      title="Info"
                                    />
                                  </div>
                                </button>
                              }
                              body={
                                <>
                                  <strong>{label}: </strong>
                                  {refundedDestinations.map((refund: any, i) => {
                                    return (
                                      <>
                                        <a
                                          href={refund.url}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          {refund.id}
                                        </a>
                                        {i < refundedDestinations.length - 1 && (
                                          <>
                                            {', '}
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              }
                              color="#0088cc"
                              position="top"
                              align="end"
                              arrowSize={5}
                              closeOnClick={true}
                              closeBtn={true}
                            />
                          </>

                        );
                      }
                      return (
                        <>
                          <button
                            className="c-btn-icon"
                            onClick={this.handleViewReceipt.bind(this, row.original.id)}
                          >
                            <div className="c-btn__inner">
                              <span
                                className="c-btn__icon fa fa-receipt"
                                title={TextOnly('viewReceipt')}
                              />
                            </div>
                          </button>
                          <RefundTransaction
                            shopID={currentShop.shopID}
                            transaction={row.original}
                            handleRefundTransaction={this.handleRefundTransaction}
                          />
                        </>
                      );
                    },
                  },
                ]}
                defaultSorted={[
                  {
                    id: 'time',
                    desc: true,
                  },
                ]}
                savedColumnsId="transactionsTableColumns"
                subComponentRenderer={TransactionSubComponent}
              />
            </DisplayDialog>

            <Dialog
              isOpen={showConfirmTerminateModal}
              onDismiss={this.handleHideTerminateModal}
              className="c-modal"
              aria-label={'Confirm Terminate Shop'}
            >
              <button
                className="c-btn-icon c-modal__close"
                onClick={this.handleHideTerminateModal}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fa fa-times" />
                </div>
              </button>
              <h1 className="c-modal__heading">Confirm Shop Termination</h1>

              <div className="c-modal__body">
                <p>Are you sure you want to terminate this shop? : </p>
                <pre>
                  <strong>{currentShop.shopName}</strong>
                </pre>
                <p>
                  If so, please confirm by typing the shop name below (case
                  sensitive).
                </p>
                <div className="c-field">
                  <label htmlFor="confirmShopName" className="c-field__label">
                    Confirm Shop Name
                  </label>
                  <input
                    autoFocus
                    id="confirmShopName"
                    name="confirmShopName"
                    maxLength={50}
                    type="text"
                    className="c-input"
                    placeholder={currentShop.shopName}
                    value={confirmShopName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="l-flex-end">
                  <button
                    className="c-btn-outline"
                    onClick={this.handleHideTerminateModal}
                  >
                    Don't Terminate
                  </button>
                  <LoaderButton
                    onClick={() => {
                      this.handleTerminateShop(currentShop.shopID);
                    }}
                    className="c-btn c-btn-error u-margin-left"
                    disabled={
                      !confirmShopName ||
                      !currentShop?.shopName ||
                      confirmShopName.trim() !== currentShop.shopName.trim()
                    }
                    isLoading={isLoading}
                    text="Terminate Shop"
                    loadingText="Terminating..."
                    icon={'fa fa-ban'}
                  />
                </div>
              </div>
            </Dialog>

            <Dialog
              isOpen={showConfirmDowngradeModal}
              onDismiss={this.handleHideDowngradeModal}
              className="c-modal"
              aria-label={'Confirm Downgrade Shop'}
            >
              <button
                className="c-btn-icon c-modal__close"
                onClick={this.handleHideDowngradeModal}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fa fa-times" />
                </div>
              </button>
              <h1 className="c-modal__heading">Confirm Shop Downgrade</h1>

              <div className="c-modal__body">
                <p>Are you sure you want to Downgrade this shop to STANDARD? : </p>
                <pre>
                  <strong>{currentShop.shopName}</strong>
                </pre>
                <p>
                  If so, please confirm by typing the shop name below (case
                  sensitive).
                </p>
                <div className="c-field">
                  <label htmlFor="confirmShopName" className="c-field__label">
                    Confirm Shop Name
                  </label>
                  <input
                    autoFocus
                    id="confirmShopName"
                    name="confirmShopName"
                    maxLength={50}
                    type="text"
                    className="c-input"
                    placeholder={currentShop.shopName}
                    value={confirmShopName}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="l-flex-end">
                  <button
                    className="c-btn-outline"
                    onClick={this.handleHideDowngradeModal}
                  >
                    Don't Downgrade
                  </button>
                  <LoaderButton
                    onClick={() => {
                      this.handleDowngradeShop(currentShop.shopID);
                    }}
                    className="c-btn c-btn-error u-margin-left"
                    disabled={
                      !confirmShopName ||
                      !currentShop?.shopName ||
                      (confirmShopName.trim() !== currentShop.shopName.trim()) ||
                      !!((currentShop?.shopUsers?.length && currentShop.shopUsers.length > 5) ||
                        (currentShop?.shopTools?.length && currentShop.shopTools.length > 5) ||
                        (!currentShop?.shopPaymentInfo?.subscriptionId) ||
                        (currentShop?.shopType !== 'PLUS') ||
                        (currentShop?.shopSubscriptionState !== 'VALID'))
                    }
                    isLoading={isLoading}
                    text="Downgrade Shop"
                    loadingText="Downgrading..."
                    icon={'fa fa-ban'}
                  />
                </div>
              </div>
            </Dialog>

            <Dialog
              isOpen={showConfirmRemoveOEMModal}
              onDismiss={this.handleHideRemoveOEMModal}
              className="c-modal"
              aria-label={'Confirm Remove OEM and Refund'}
            >
              <button
                className="c-btn-icon c-modal__close"
                onClick={this.handleHideRemoveOEMModal}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fa fa-times" />
                </div>
              </button>
              <h1 className="c-modal__heading">Confirm Remove OEM and Refund</h1>

              <div className="c-modal__body">
                <p>Are you sure you want to Remove <strong>{this.state.oemInfo[this.state.oemToRemove]}</strong> and Refund? : </p>
                <pre>
                  <strong>{currentShop.shopName}</strong>
                </pre>
                <p>
                  If so, please confirm by typing the shop name below (case
                  sensitive).
                </p>
                <div className="c-field">
                  <label htmlFor="confirmShopName" className="c-field__label">
                    Confirm Shop Name
                  </label>
                  <input
                    autoFocus
                    id="confirmShopName"
                    name="confirmShopName"
                    maxLength={50}
                    type="text"
                    className="c-input"
                    placeholder={currentShop.shopName}
                    value={confirmShopName}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="l-flex-end">
                  <button
                    className="c-btn-outline"
                    onClick={this.handleHideRemoveOEMModal}
                  >
                    Don't Remove OEM
                  </button>
                  <LoaderButton
                    onClick={() => {
                      this.handleRemoveOEM(currentShop.shopID, this.state.oemToRemove);
                    }}
                    className="c-btn c-btn-error u-margin-left"
                    disabled={
                      !confirmShopName ||
                      !currentShop?.shopName ||
                      (confirmShopName.trim() !== currentShop.shopName.trim())
                    }
                    isLoading={isLoading}
                    text="Remove OEM"
                    loadingText="Removing..."
                    icon={'fa fa-ban'}
                  />
                </div>
              </div>
            </Dialog>

            <Dialog
              isOpen={this.state.showReceiptModal}
              onDismiss={this.handleCancelReceiptModal.bind(this)}
              className="c-modal"
              aria-label={TextOnly('autoAuthReceipt')}
            >
              <button
                className="c-btn-icon c-modal__close"
                onClick={this.handleCancelReceiptModal.bind(this)}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-times" />
                </div>
              </button>

              <div className="c-modal__body">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '9rem',
                  }}
                >
                  <Document
                    file={this.state.currentReceipt?.data || null}
                    loading={TextOnly('loading')}
                  >
                    <Page pageNumber={1} scale={this.state.pdfScale} />
                  </Document>
                </div>
                <div>
                  <button
                    className="c-btn-outline"
                    onClick={this.handleCancelReceiptModal.bind(this)}
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            </Dialog>
          </>
        )}
      </>
    );
  }
}
