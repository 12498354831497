import React, { useEffect, useState } from 'react';
import ReactTable, {
  Column,
  SubComponentFunction,
  ComponentPropsGetterR,
  ComponentPropsGetter0,
} from 'react-table';
import _ from 'underscore';

import { generateFilterRegex } from '../libs/utils';
import { getSavedColumns } from '../libs/utils-ts';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Switch from './Switch';

interface GenericTableProps {
  id?: string;
  data: any[];
  filterKeys: string[];
  columnDefs: any[];
  defaultSorted: any[];
  defaultPageSize?: number;
  savedColumnsId: string;
  loadingText?: string | JSX.Element;
  loading?: boolean;
  subComponentRenderer?: SubComponentFunction;
  getTrProps?: ComponentPropsGetterR | ComponentPropsGetter0;
  fetchDataTriggered?: boolean;
  resetFetchDataTriggered?: Function;
}

const GenericTable = ({
  id,
  data,
  filterKeys,
  columnDefs,
  defaultSorted,
  defaultPageSize,
  savedColumnsId,
  loadingText,
  loading,
  subComponentRenderer,
  getTrProps,
  fetchDataTriggered,
  resetFetchDataTriggered,
}: GenericTableProps) => {
  const [dataFiltered, setDataFiltered] = useState(data || []);
  const [filter, setFilter] = useState('');
  const [expanded, setExpanded] = useState({});
  const [expandAll, setExpandAll] = useState(
    JSON.parse(window.localStorage.getItem('expandAllTableRows') as string) ||
      false
  );
  const savedColumnSizes = getSavedColumns(savedColumnsId || '');

  // Pagination for mobile table
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageText, setCurrentPageText] = useState(1);
  const [numCards, setNumCards] = useState(5);

  // Sorting for mobile table
  const [sortProperty, setSortProperty] = useState(defaultSorted[0].id);
  const [sortDesc, setSortDesc] = useState(defaultSorted[0].desc);

  let parentTable = document.getElementById(id as string);

  useEffect(() => {
    let dataList = data
      ? data.map((dataItem: any) => {
          return dataItem;
        })
      : [];

    let expandedObj: { [k: number]: any } = {};
    setDataFiltered(dataList);
    if (
      JSON.parse(
        window.localStorage.getItem('expandAllTableRows') as string
      ) === true
    ) {
      dataList.forEach((value, index) => {
        expandedObj[index] = {};
      });
    }
    else {
      dataList.forEach((value, index) => {
        expandedObj[index] = false;
      });
    }
    setExpanded(expandedObj);
  }, [data, currentPage, numCards]);

  useEffect(()=>{
    if (fetchDataTriggered) {
      resetFetchDataTriggered?.();
      const expandedObj: { [k: number]: any } = {};
      dataList.forEach((value, index) => {
        expandedObj[index] = false;
      });
    }
  },[fetchDataTriggered]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    updateFilter(e.target.value);
  };

  const updateFilter = _.debounce((f: string) => {
    updateFilteredList(f);
  }, 300);

  const updateFilteredList = (filter: string) => {
    let dataList = data;

    let elements = parentTable?.querySelectorAll('.card-td, .rt-td');

    if (filter.trim().length > 0) {
      const regexStr = generateFilterRegex(filter);

      dataList = data.filter((u) => {
        if (filterKeys.some((key) => regexStr.test(u[key]))) {
          return true;
        }
        return false;
      });

      elements?.forEach((element) => {
        if (filter !== '' && element.textContent?.includes(filter.trim())) {
          element.classList.add('highlighted');
        } else {
          element.classList.remove('highlighted');
        }
      });
    } else {
      elements?.forEach((element) => {
        element.classList.remove('highlighted');
      });
    }

    setDataFiltered(dataList || []);
  };

  // Table Columns
  const columns: Column[] = columnDefs.map((colDef) => {
    if (colDef.Header === 'Manufacturer'){
      colDef = {
        ...colDef,
        id: colDef.accessor,
        width: 120,
      };
      return colDef;
    } else if (colDef.Header === 'Model'){
      colDef = {
        ...colDef,
        id: colDef.accessor,
        width: 100,
      };
      return colDef;
    } else if (colDef.Header === 'Serial'){
      colDef = {
        ...colDef,
        id: colDef.accessor,
        width: 155,
      };
      return colDef;
    } else if (colDef.Header === 'Added On'){
      colDef = {
        ...colDef,
        id: colDef.accessor,
        width: 200,
      };
      return colDef;
    }else{
      colDef = {
        ...colDef,
        id: colDef.accessor,
        minWidth: savedColumnsId
          ? savedColumnSizes[colDef.accessor] || colDef.minWidth || 120
          : 120,
      };
      return colDef;
    }
  });

  const mobileColumns = columnDefs.map((col) => col.Header);
  const mobileCellRender = columnDefs.map((col) => {
    let accessor = typeof col.accessor === 'string' ? col.accessor : col.id;
    if (col.Cell !== undefined) {
      return { accessor: accessor, func: col.Cell };
    }
    return accessor;
  });

  let dataList = dataFiltered
    ? dataFiltered.map((dataItem: any) => {
        return dataItem;
      })
    : [];

  if (sortProperty && sortDesc !== undefined) {
    dataList.sort((a, b) => {
      let retValue = 0;
      const aVal = a[sortProperty];
      const bVal = b[sortProperty];

      if (sortDesc) {
        if (sortProperty === 'amount') {
          retValue = bVal - aVal;
        } else {
          retValue = bVal.localeCompare(aVal);
        }
      } else {
        if (sortProperty === 'amount') {
          retValue = aVal - bVal;
        } else {
          retValue = aVal.localeCompare(bVal);
        }
      }
      return retValue;
    });
  }

  let totalPages = Math.ceil(dataList.length / numCards);
  let paginatedData = dataList.slice(
    (currentPage - 1) * numCards,
    (currentPage - 1) * numCards + numCards
  );

  return (
    <div className="table-container" id={id}>
      {subComponentRenderer && (
        <Switch
          isChecked={expandAll}
          states={{ active: 'Collapse All Rows', inactive: 'Expand All Rows' }}
          handleCheck={(e) => {
            let expandedObj: { [k: number]: any } = {};
            if (e) {
              dataList.forEach((value, index) => {
                expandedObj[index] = {};
              });
              setExpandAll(true);
            } else {
              dataList.forEach((value, index) => {
                expandedObj[index] = false;
              });
              setExpandAll(false);
            }
            setExpanded(expandedObj);
          }}
        />
      )}
      <div className="c-field u-margin-top-small u-margin-right-small">
        <label htmlFor="filter" className="c-field__label u-is-vishidden">
          Filter
        </label>
        <input
          type="text"
          id="filter"
          maxLength={50}
          className="c-input u-font-mono"
          placeholder="Filter"
          value={filter}
          onChange={handleChange}
        />
        <i className="c-field__input-icon fal fa-search" />
      </div>

      <div className="u-margin-top-xlarge">
        <ReactTable
          columns={columns}
          data={dataList}
          className="-highlight"
          previousText="Previous Page"
          nextText="Next Page"
          pageText="Page"
          ofText="Of"
          rowsText="Rows"
          noDataText={!loading && 'No Data Yet'}
          onPageChange={(page) => {
            let expandedObj: { [k: number]: any } = {};
            dataList.forEach((value, index) => {
              expandedObj[index] = false;
            });
            setCurrentPage(page + 1);
            setCurrentPageText(page + 1);
           }}
          loadingText={
            loadingText ? (
              <div className="l-container-center l-flex-column">
                <strong>{loadingText}</strong>{' '}
                <i
                  className="fal fa-spinner-third spinning c-btn-icon"
                  title={loadingText as string}
                />
              </div>
            ) : (
              <div className="l-container-center l-flex-column">
                <strong>Loading...</strong>{' '}
                <i
                  className="fal fa-spinner-third spinning c-btn-icon"
                  title={'Loading...'}
                />
              </div>
            )
          }
          loading={loading || false}
          expanded={expanded}
          onExpandedChange={(expanded, index, event) => {
            setExpanded(expanded);
          }}
          defaultPageSize={defaultPageSize || 10}

          defaultSorted={defaultSorted}
          onResizedChange={(a) =>
            localStorage.setItem(savedColumnsId, JSON.stringify(a))
          }
          SubComponent={subComponentRenderer}
          getTrProps={getTrProps}
        />

        <div className="responsiveTable u-margin-bottom-large">
          <div className="c-field u-margin-top-small u-margin-right-small">
            <div className="l-flex-gap-1">
              <div className="c-select">
                <select
                  aria-label="sort by selector"
                  onChange={(e) => {
                    setSortDesc(undefined);
                    setSortProperty(e.target.value);
                  }}
                  defaultValue={sortProperty}
                >
                  {columnDefs.map((col) => {
                    if (col.Header === 'Actions') return <React.Fragment key={'actions'} />;
                    return (
                      <option
                        value={
                          typeof col.accessor === 'string'
                            ? col.accessor
                            : col.id
                        }
                        key={col.Header}
                      >
                        {col.Header}
                      </option>
                    );
                  })}
                </select>
              </div>
              <button className="c-btn-icon" onClick={() => setSortDesc(false)}>
                <div className="c-btn__inner">
                  <i className="c-btn__icon fa fa-chevron-up" />
                </div>
              </button>
              <button className="c-btn-icon" onClick={() => setSortDesc(true)}>
                <div className="c-btn__inner">
                  <i className="c-btn__icon fa fa-chevron-down" />
                </div>
              </button>
            </div>
          </div>

          {dataList.length === 0 && (
            <div className="l-flex-center">
              <strong>No Data Yet</strong>
            </div>
          )}
          <Table>
            <Thead>
              <Tr>
                {mobileColumns.map((col) => {
                  return <Th key={col}>{col}</Th>;
                })}
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.map((item, idx) => {
                return (
                  <Tr key={idx}>
                    {mobileCellRender.map((col) => {
                      if (typeof col !== 'string') {
                        return (
                          <Td key={col}>{col.func({ original: item })}</Td>
                        );
                      }
                      return <Td key={col}>{item[col as string]}</Td>;
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <div className="c-card">
            <div className="c-select">
              <select
                aria-label="cards per page"
                onChange={(e) => {
                  setNumCards(parseInt(e.target.value));
                }}
              >
                <option value="5">5 Cards</option>
                <option value="10">10 Cards</option>
                <option value="20">20 Cards</option>
                <option value="25">25 Cards</option>
                <option value="50">50 Cards</option>
                <option value="100">100 Cards</option>
              </select>
            </div>

            <div className="l-flex-between l-flex-align-center u-margin-top-small">
              <button
                type="button"
                className="c-btn"
                disabled={currentPage === 1}
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                    setCurrentPageText(currentPage - 1);
                  }
                }}
              >
                Prev
              </button>

              <div>
                <span className="pagination-input">
                  <input
                    type="number"
                    value={currentPageText}
                    onChange={(e) => {
                      if (
                        e.target.value === '0' ||
                        parseInt(e.target.value) > totalPages
                      )
                        return;
                      if (e.target.value !== '') {
                        setCurrentPage(parseInt(e.target.value));
                      }
                      setCurrentPageText(parseInt(e.target.value));
                    }}
                    onBlur={(e) => {
                      setCurrentPageText(currentPage);
                    }}
                  ></input>
                  /<strong>{totalPages}</strong>
                </span>
                <span className=""></span>
              </div>

              <button
                type="button"
                className="c-btn"
                disabled={currentPage === totalPages}
                onClick={() => {
                  if (currentPage < totalPages) {
                    setCurrentPage(currentPage + 1);
                    setCurrentPageText(currentPage + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericTable;
